import React, { useState } from 'react'
import config from '../../config.json'
import CryptoJS from 'crypto-js'


const ChangePasswordPopUp = () => {
    const sURL = config.apiUrl

    const [errormessage, setErrorMessage] = useState('')
    const [currentpassword, setCurrentPassword] = useState([]);
    const [newpassword, setNewPassword] = useState([]);
    const [confirmpassword, setConfirmPassword] = useState([]);

    const HandleSubmit = (e) => {
        e.preventDefault();
        console.log(currentpassword)
        console.log(newpassword)
        console.log(confirmpassword)
        if (currentpassword !== '' && newpassword !== '' && confirmpassword !== '') {
            if (newpassword === confirmpassword) {

                const url = sURL + 'api/Authentication/ChangePassword'

                const options = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json-patch+json', 'Authorization': 'bearer ' + sessionStorage.getItem('token') },
                    //credentials: 'include',
                    body: JSON.stringify({ Email_Id: sessionStorage.getItem('email'), Password: CryptoJS.SHA256(currentpassword).toString(), New_Password: CryptoJS.SHA256(newpassword).toString() })
                }

                fetch(url, options)
                    .then(response => response.json())
                    .then(data => console.log(data.json()))
                    .catch(error => console.error(error))
            }
        }
    }
    return (
        <div>
            <h6>Change password</h6>
            <form onSubmit={HandleSubmit}>
                <table className='ChangePassword'>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            Current Password
                        </td>
                    </tr>
                    <tr>
                        <td><input className='form-control' type='password' name='Current_Password' onChange={(e) => setCurrentPassword(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>New Password</td>
                    </tr>
                    <tr>
                        <td> <input type='password' name='New_Password' className='form-control' onChange={(e) => setNewPassword(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>Confirm Password</td>
                    </tr>
                    <tr>
                        <td> <input type='password' name='Confirm_Password' className='form-control' onChange={(e) => setConfirmPassword(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td><button type='button' className='btn btn-block mb-6' style={{ backgroundColor: 'tomato', width: '100px', fontSize: '12px' }} onClick={HandleSubmit}>Change</button></td>
                    </tr>
                    <tr>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            {errormessage}
                        </td>
                    </tr>
                </table>
            </form>
        </div>
    )
}

export default ChangePasswordPopUp