import React, { useState, useEffect } from 'react';
import config from '../config.json'
import { IoSaveOutline } from "react-icons/io5";
import { IoLockClosed } from "react-icons/io5";


const ApproveEffortPopup = (props) => {
    const [weeks, setWeeks] = useState([]);
    const [message, setMessage] = useState('');
    const [selectedstartdate, setSelectedStartDate] = useState('')
    const [selectedenddate, setSelectedEndDate] = useState('')

    const email = sessionStorage.getItem('email')
    const sURL = config.apiUrl//'http://localhost:5250/' //'https://82.33.19.139:9008/' 
    useEffect(() => {
        const generateWeeksInMonth = (year, month) => {
            const startOfMonth = new Date(year, month - 1, 1);
            const endOfMonth = new Date(year, month, 0);

            const weeksInMonth = [];
            let startOfWeek = startOfMonth;

            // First week: from 1st to the first Sunday
            let endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(startOfWeek.getDate() + (7 - startOfWeek.getDay()) % 7);

            if (endOfWeek > endOfMonth) {
                endOfWeek = endOfMonth;
            }
            weeksInMonth.push({ start: new Date(startOfWeek), end: new Date(endOfWeek) });

            // Subsequent weeks: from Monday to Sunday
            startOfWeek = new Date(endOfWeek);
            startOfWeek.setDate(startOfWeek.getDate() + 1);

            while (startOfWeek <= endOfMonth) {
                endOfWeek = new Date(startOfWeek);
                endOfWeek.setDate(startOfWeek.getDate() + 6);

                if (endOfWeek > endOfMonth) {
                    endOfWeek = endOfMonth;
                }

                weeksInMonth.push({ start: new Date(startOfWeek), end: new Date(endOfWeek) });

                startOfWeek = new Date(endOfWeek);
                startOfWeek.setDate(startOfWeek.getDate() + 1);
            }

            return weeksInMonth;
        };

        setWeeks(generateWeeksInMonth(props.cyear, props.cmonth));
    }, [props.cyear, props.cmonth]);

    const HandleChange = (e) => {

        const selectedOption = e.target.options[e.target.selectedIndex];
        if (selectedOption != 0) {
            setSelectedStartDate(formatDate2(selectedOption.getAttribute('dstart')))
            setSelectedEndDate(formatDate2(selectedOption.getAttribute('dend')))
        }
    }

    function formatDate2(dateString) {

        const date = new Date(dateString);
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }

        const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits (e.g., 01 instead of 1)
        const month = date.getMonth() + 1
        const year = date.getFullYear(); // Get full year
        if (month < 10) {
            return (`${year}-0${month}-${day}`);
        }
        else {
            return (`${year}-${month}-${day}`);
        }
    }
    const handleSubmit = async (e) => {

        if (selectedstartdate != '' && selectedenddate != '') {

            try {

                const url = sURL + 'api/Effort/SubmitEffortsByWeek/'

                const options = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json-patch+json', 'Authorization': 'bearer ' + sessionStorage.getItem('token') },
                    //credentials: 'include',
                    body: JSON.stringify({ Email: email, StartDate: selectedstartdate, EndDate: selectedenddate })
                }

                const response = await fetch(url, options)
                const data = await response.json()
                console.log(data)
                if (data.status) {
                    setMessage('Effort Sent for approval')
                }
                else {
                    setMessage('Error occured')
                }
            }
            catch (error) {
                console.error(error)
            }
            finally {
                //setIsLoading(false);
            }
        }
    }

    return (
        <div>
            <h6>Submit Effort</h6>
            <form>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'left' }}>
                                <label>Select a week</label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <select onChange={HandleChange}>
                                    <option key={0} value={0}>Select</option>
                                    {weeks.map((week, index) => (
                                        <option key={index} value={week} dstart={week.start} dend={week.end}>
                                            {`${week.start.toLocaleDateString('en-US', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' })} - ${week.end.toLocaleDateString('en-US', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' })}`}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td><label style={{ color: 'tomato', textAlign: 'left', fontWeight: 'bold' }}>Please save the efforts before submitting!</label></td>
                        </tr>
                        <tr>
                            <td>
                                <button type='button' class="btn btn-block mb-6" style={{ backgroundColor: 'hsl(24, 100%, 50%)', height: '35px' }} onClick={handleSubmit}><IoLockClosed style={{ paddingBottom: '2px' }} />Submit</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <br />
                                <br />
                                <label>{message}</label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div >
    );
};

export default ApproveEffortPopup;
