import React, { useState } from 'react';

const FormDropdown = ({ controlname, options, OnDWChange, alreadyexist }) => {
    const opt = options
    const [selected, setSelected] = useState('')

    const Handlechange = (e) => {
        setSelected(e.target[e.target.selectedIndex].value)
        OnDWChange(e.target[e.target.selectedIndex].value)
    }
    return (
        <div className='timesheet-dropdown'>
            <select name={controlname} className='form-select owndrop' onChange={Handlechange}>
                <option style={{ backgroundColor: 'white' }} id={0} value={0}>Select Task</option>
                {
                    opt.map((item, index) => !alreadyexist.includes(item.id) && (
                        <option style={{ backgroundColor: 'white' }} id={index} value={item.id}>{item.project_Name}</option>
                    ))
                }
            </select>
            {/* <input placeholder={props.Placeholder} type={props.type} name={props.name}></input> */}
        </div>

    );
};

export default FormDropdown;