import React, { useEffect, useState } from 'react';
import logo from '../Img/logo-xl.png'
import { IoArrowBackCircle } from "react-icons/io5";
import { IoArrowForwardCircle } from "react-icons/io5";
import SlidingNavBar from '../Admin/SlidingNavBar';
import SideNote from './SideNote';
import { IoSaveOutline } from "react-icons/io5";
import FormDropdown from '../Common/components/FormDropdown';
import { IoLockClosed } from "react-icons/io5";
import { useNavigate } from 'react-router-dom'
import { FaPlus } from "react-icons/fa";
import '../custom.css'
import NotePopup from './NotePopup';
import ChangePasswordPopUp from '../Authentication/Components/ChangePasswordPopUp'
import config from '../config.json'
import ApproveEffortPopup from './ApproveEffortPopup'

const Timesheet4 = () => {

    const email = sessionStorage.getItem('email')
    const navigate = useNavigate();
    const sURL = config.apiUrl//'http://localhost:5250/' //'https://82.33.19.139:9008/' 
    const [data, setData] = useState([]);
    const [effortsData, setEffortsData] = useState([]);
    const [notesData, setNoteData] = useState([]);
    const [allocatedProjects, setAllocatedProjects] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [selectedProject, SetSelectedProject] = useState('');
    const [cmonth, setCMonth] = useState(new Date().getMonth() + 1)
    const [cyear, setCYear] = useState(new Date().getFullYear())
    const [isLoading, setIsLoading] = useState(true);
    const [colcount, setColCount] = useState([]);
    const [filterproject, setFilteredproject] = useState([]);
    const [notepopup, SetNotePopUp] = useState(false)
    const [worknotedate, setWorkNoteDate] = useState();
    const [errormessage, setErrorMessage] = useState('')
    const [changepasswordpopup, setChangePasswordPopup] = useState(false)
    const [approveeffortpopup, setApproveEffortPopup] = useState(false)

    if (sessionStorage.getItem('email') === null && sessionStorage.getItem('token') === null) {
        navigate('/')
    }
    useEffect(() => {
        fetchData()
    }, [email, cmonth, cyear])

    const fetchData = async () => {
        try {
            const response = await fetch(sURL + `api/Activity/GetActivitiesByEmail/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': 'bearer ' + sessionStorage.getItem('token')
                },
                //credentials: 'include',
                body: JSON.stringify({ Email_Id: email, Effort_Month: cmonth, Effort_Year: cyear })
            });
            const data = await response.json();
            setEffortsData(data.activtyList)
            setNoteData(data.notes)
            setAllocatedProjects(data.allocated_Projects)
            setEmployee(data.employee)
            console.log(data)

            sessionStorage.setItem('EmpName', data.employee.name)
            sessionStorage.setItem('EmpNo', data.employee.emp_No)

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    }

    console.log(notesData)
    const dates = [...new Set(effortsData.map(item => item.effort_date))];
    const projects = [...new Set(effortsData.map(item => item.project_Guid))];

    useEffect(() => {
        setColCount(Array.from({ length: 7 - projects.length }, (_, i) => i + 1))
        console.log(colcount)
        // setColCount(8 - projects.length); // Update colcount when projects change
    }, []);

    function formatDate(dateString) {

        const date = new Date(dateString);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }

        const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits (e.g., 01 instead of 1)
        const month = date.toLocaleString('default', { month: 'short' }); // Get abbreviated month name
        const year = date.getFullYear(); // Get full year
        const weeksday = date.toLocaleString('default', { weekday: 'short' })
        return (`${day} ${weeksday}`);
    }

    function formatDate2(dateString) {

        const date = new Date(dateString);
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }

        const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits (e.g., 01 instead of 1)
        const month = date.getMonth() + 1
        const year = date.getFullYear(); // Get full year
        if (month < 10) {
            return (`${year}-0${month}-${day}`);
        }
        else {
            return (`${year}-${month}-${day}`);
        }
    }

    const handleSave = async (e) => {
        setIsLoading(true);
        const url = sURL + 'api/Activity/UpdateEfforts/'
        console.log(effortsData)

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json-patch+json', 'Authorization': 'bearer ' + sessionStorage.getItem('token') },
            //credentials: 'include',
            body: JSON.stringify({ email_Id: email, effort_Month: cmonth, effort_Year: cyear, effort_data: effortsData, effort_Notes: notesData })
        }
        try {
            const response = await fetch(url, options)
            const data = await response.json()
            await fetchData();
        }
        catch (error) {
            console.log(error)
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleSubmit = async (e) => {

        setApproveEffortPopup(true)

        // try {
        //     setIsLoading(true);

        //     const url = sURL + 'api/Activity/SubmitEfforts/'
        //     console.log(effortsData)

        //     const options = {
        //         method: 'POST',
        //         headers: { 'Content-Type': 'application/json-patch+json', 'Authorization': 'bearer ' + sessionStorage.getItem('token') },
        //         //credentials: 'include',
        //         body: JSON.stringify({ email_Id: email, effort_Month: cmonth, effort_Year: cyear, effort_data: effortsData, effort_Notes: notesData })
        //     }

        //     const response = await fetch(url, options)
        //     const data = await response.json()
        //     await fetchData()
        // }
        // catch (error) {
        //     console.error(error)
        // }
        // finally {
        //     setIsLoading(false);
        // }
    }

    const hanldePreviousWeek = (e) => {

        if (cmonth === 1) {

            setCMonth(12)
            setCYear(cyear - 1)
        }
        else {
            setCMonth(cmonth - 1)
            setCYear(cyear)
        }
        setIsLoading(true)

    }
    const hanldeNextWeek = (e) => {
        if (cmonth === 12) {
            setCMonth(1)
            setCYear(cyear + 1)
        }
        else {
            setCMonth(cmonth + 1)
            setCYear(cyear)
        }
        setIsLoading(true)
    }

    const weekendcolor = (date) => {
        let day = new Date(date).toLocaleString('default', { weekday: 'short' })

        if (day === 'Sun' || day === 'Sat') {
            return 'rgb(255, 218, 185)'//'rgb(211, 211, 211)' //'rgb(255, 160, 122)'
        }
        else { return 'rgb(255, 255, 255)' }
    }

    const handleDWchange = (newdata) => {
        SetSelectedProject(newdata)

        console.log(projects.includes(item => item === newdata))

        if (projects.includes(item => item === newdata) === false) {

            const updata = dates.map((date, index) => ({
                project_Guid: newdata,
                employee_Id: employee.id,
                project_Name: allocatedProjects.find(proj => proj.id === newdata).project_Name,
                efforts: 0,
                effort_date: date,
                effort_Status: 0
            }))

            const updatanote = dates.map((date, index) => ({
                employee_Id: employee.id,
                created_Date: formatDate2(date),
                description: '',
                status: 0,
                project_Id: newdata,
            }))
            setEffortsData(prevData => [...prevData, ...updata]);
            setNoteData(prevData => [...prevData, ...updatanote]);
            setColCount(Array.from({ length: 9 - projects.length }, (_, i) => i + 1))
        }
        console.log(effortsData)
    }

    const updatedata = (e, project, date) => {
        const updata = [...effortsData]
        updata.find(item => item.project_Guid === project && item.effort_date === date).efforts = parseFloat(e.target.value)
        updata.find(item => item.project_Guid === project && item.effort_date === date).edit_Status = 1
        setEffortsData(updata)
        console.log(effortsData)
    }

    const updatenotes = (e, date) => {
        console.log('Hi')
        const updata = [...notesData]
        // if (notesData.includes(item => formatDate2(item.created_Date) === formatDate2(date)) === false) {

        //     const newdata = {
        //         created_Date: formatDate2(date),
        //         description: e.target.value,
        //         employee_Id: employee.id,
        //         project_Id: '',
        //         project_Name: ''
        //     }

        //     setNoteData(prevData => [...prevData, newdata])
        // }
        // else {
        updata.find(item => formatDate2(item.created_Date) === formatDate2(date)).description = e.target.value
        setNoteData(updata)
        // }

        console.log(updata)
    }

    const togglePopup = async (e) => {
        SetNotePopUp(false)
        setChangePasswordPopup(false)
        setApproveEffortPopup(false)
    }

    const HandleNotesEdit = async (e, date) => {
        SetNotePopUp(true)
        setWorkNoteDate(date)
    }

    const HanldeNoteChanges = (unotesdata) => {
        SetNotePopUp(false)
        setNoteData(unotesdata)
        console.log(unotesdata)
    }

    const HandleLogout = (e) => {
        sessionStorage.clear();
        navigate('/')
    }

    const HandleChangePassword = (e) => {
        setChangePasswordPopup(true)
    }

    return (
        <div className='timesheet4'>
            <section style={{ backgroundColor: 'hsl(0, 0%, 26%)', height: '100%' }}>
                <div className="px-5 py-6 px-md-6 text-center text-lg-start" style={{ fontSize: '12px' }}>
                    <div className='container h-100'>
                        <div>
                            <br></br>
                            <img src={logo} alt='UK Powers' style={{ height: '32px', width: '180px' }} />
                        </div>
                        <br></br>
                    </div>
                </div>
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                        <div class="spinner-grow" role="status">
                            <span class="sr-only"></span>
                        </div>
                        <div class="spinner-grow" role="status">
                            <span class="sr-only"></span>
                        </div>
                        <div class="spinner-grow" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                ) : (
                    <div style={{ backgroundColor: 'hsl(0, 0%, 96%)', color: 'hsl(217, 10%, 50.8%)' }}>

                        <div className='admin-control'>
                            <div><h6>Welcome {employee.name} &nbsp; &nbsp; Employee No: {employee.emp_No}</h6></div>{sessionStorage.getItem('admin') === 'true' && <><div><button className='btn btn-light' onClick={(e) => navigate('/timesheet')}>Home</button></div><div><button className='btn btn-light' onClick={(e) => navigate('/admin')}>Settings</button></div><div><button className='btn btn-light' onClick={(e) => navigate('/report')}>Report</button></div><div><button className='btn btn-light' onClick={(e) => navigate('/approve')}>Approve</button></div><br /><br /></>}<div><button className='btn btn-light' onClick={HandleChangePassword}>Change Password</button></div><div><button className='btn btn-light' onClick={HandleLogout}>Logout</button></div>
                        </div>
                        <div className='admin-control'>{errormessage}</div>
                        <div className='timesheetcontrols'>

                            <div className='timesheetcontrolsboxleft'><IoArrowBackCircle onClick={hanldePreviousWeek} style={{ height: '30px', width: '30px' }} /></div>
                            <div className='timesheetcontrolsboxcenter' style={{ textAlign: 'center' }}><label style={{ color: 'hsl(24, 100%, 50%)', fontSize: '16px' }}>{new Date(dates[0]).toLocaleString('default', { month: 'short' })}-{new Date(dates[0]).getFullYear()}</label></div>
                            <div className='timesheetcontrolsboxend'>
                                <button type="button" class="btn btn-light" onClick={handleSave}><IoSaveOutline style={{ paddingBottom: '2px' }} /> Save</button>&nbsp; &nbsp; {/* btn btn-block mb-6 style={{ backgroundColor: 'hsl(24, 100%, 50%)', height: '35px', border: '1px solid' }}*/}
                                <button type="button" class="btn btn-light" onClick={handleSubmit}><IoLockClosed style={{ paddingBottom: '2px' }} /> Submit</button> &nbsp; &nbsp;
                                <IoArrowForwardCircle onClick={hanldeNextWeek} style={{ height: '30px', width: '30px' }} /></div>
                        </div>
                        <form>
                            <table className='table' style={{ tableLayout: 'fixed', width: '100%' }}>
                                <thead class="tablehead">
                                    <tr>
                                        <td className='tdhorizontal'><p>Task</p></td>
                                        {
                                            projects.map((record, index) =>
                                                <td className='tdvertical'>
                                                    <div className='timesheet-dropdown'>
                                                        <label>
                                                            {allocatedProjects.find(item => item.id === record).project_Name}
                                                        </label>
                                                    </div>
                                                </td>
                                            )
                                        }
                                        {
                                            colcount.map((count, index) =>
                                                <td className='tdvertical'>
                                                    <FormDropdown controlname='Project' options={allocatedProjects} OnDWChange={handleDWchange} alreadyexist={projects}></FormDropdown>
                                                </td>
                                            )
                                        }

                                        <td className='tdvertical' style={{ backgroundColor: 'lightgray' }}>
                                            <div className='timesheet-dropdown'>
                                                <label>Daily Total</label>
                                            </div>
                                        </td>
                                        <td className='timesheet4notes'>Work Description</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dates.map((date, index) =>
                                        <tr>
                                            <td style={{ backgroundColor: weekendcolor(date) }}> <label style={{ backgroundColor: weekendcolor(date) }}>{formatDate(date)}</label></td>

                                            {
                                                projects.map((project, index) => (
                                                    <>
                                                        {effortsData.find(item => item.effort_date === date && item.project_Guid === project).effort_Status === 0 ? (<td style={{ backgroundColor: weekendcolor(date) }}><input type='text' className='form-control' defaultValue={effortsData.find(item => item.effort_date === date && item.project_Guid === project).efforts} onChange={(e) => updatedata(e, project, date)} style={{ backgroundColor: weekendcolor(date), fontSize: '12px' }}></input></td>) : (
                                                            <td style={{ backgroundColor: weekendcolor(date) }}><label style={{ backgroundColor: weekendcolor(date) }}> {effortsData.find(item => item.effort_date === date && item.project_Guid === project).efforts}</label></td>
                                                        )}


                                                    </>
                                                ))}
                                            {
                                                colcount.map((count, index) =>
                                                    <td style={{ backgroundColor: weekendcolor(date) }}>
                                                        <label style={{ backgroundColor: weekendcolor(date) }}>0</label>
                                                    </td>
                                                )}
                                            <>
                                                <td className='total-color'><label>{(effortsData.filter((eff, ind) => eff.effort_date === date).reduce((acc, curr) => acc + curr.efforts, 0))}</label></td>
                                                {

                                                    (notesData.find(item => formatDate2(item.created_Date) === formatDate2(date)) !== undefined) ?
                                                        (
                                                            ((notesData.find(item => formatDate2(item.created_Date) === formatDate2(date)).status) === 0) ? (
                                                                <td style={{ backgroundColor: weekendcolor(date), display: 'flex' }}>

                                                                    <label style={{ width: '100%', overflow: 'hidden' }}>
                                                                        {
                                                                            // (notesData.find(item => formatDate2(item.created_Date) === formatDate2(date))).description
                                                                            (() => {
                                                                                const item = notesData.filter(item => formatDate2(item.created_Date) === formatDate2(date) && item.description !== '');
                                                                                return item.map(items => items.description).join(' | ');
                                                                            })()
                                                                        }
                                                                    </label><FaPlus onClick={(e) => HandleNotesEdit(e, date)} style={{ height: '25px' }} />
                                                                </td>

                                                            ) : (
                                                                // <td style={{ backgroundColor: weekendcolor(date) }}><label style={{ textAlign: 'left', backgroundColor: weekendcolor(date) }}> {(notesData.find(item => formatDate2(item.created_Date) === formatDate2(date))).description.map(item => item.description).join('')} </label></td>
                                                                <td style={{ backgroundColor: weekendcolor(date) }}>
                                                                    <label style={{ textAlign: 'left', backgroundColor: weekendcolor(date), overflow: 'hidden', width: '100%', }}>
                                                                        {(() => {
                                                                            const item = notesData.filter(item => formatDate2(item.created_Date) === formatDate2(date) && item.description !== '');
                                                                            return item.map(items => items.description).join(' | ');
                                                                        })()}
                                                                    </label>
                                                                </td>

                                                            )
                                                        )
                                                        :
                                                        (
                                                            <td style={{ backgroundColor: weekendcolor(date), display: 'flex' }}><input type='text' className='form-control' style={{ textAlign: 'left', backgroundColor: weekendcolor(date) }} onChange={(e) => updatenotes(e, date)}></input>&nbsp;<FaPlus onClick={(e) => HandleNotesEdit(e, date)} style={{ height: '25px' }} /></td>
                                                        )
                                                }
                                            </>
                                        </tr>)}
                                    <tr className='total-color'>
                                        <td><label>Hours</label></td>
                                        {
                                            projects.map((project, index) => (

                                                <td>
                                                    <label>{(effortsData.filter((eff, ind) => eff.project_Guid === project)).reduce((acc, curr) => acc + curr.efforts, 0)}</label>
                                                </td>
                                            ))}
                                        {
                                            colcount.map((count, index) =>
                                                <td>
                                                    <label>0</label>
                                                </td>
                                            )
                                        }
                                        <td><label>{effortsData.reduce((acc, curr) => acc + curr.efforts, 0)}</label></td>
                                        <td></td>
                                    </tr>
                                    <tr className='total-color'>
                                        <td><label>Man Days</label></td>
                                        {
                                            projects.map((project, index) => (
                                                <td>
                                                    <label>{((effortsData.filter((eff, ind) => eff.project_Guid === project)).reduce((acc, curr) => acc + curr.efforts, 0) / 8).toFixed(1)}</label>
                                                </td>
                                            ))}
                                        {
                                            colcount.map((count, index) =>
                                                <td>
                                                    <label>0</label>
                                                </td>
                                            )
                                        }
                                        <td><label>{((effortsData.reduce((acc, curr) => acc + curr.efforts, 0)) / 8).toFixed(1)}</label></td>
                                        <td></td>
                                    </tr>

                                    <tr className='total-color'>
                                        <td><label>Balance Hrs</label></td>
                                        {
                                            projects.map((project, index) => (
                                                <td>
                                                    {allocatedProjects.find(all => all.id === project && all.isInternal === false) ? (
                                                        <label style={{ color: ((allocatedProjects.find(alloc => alloc.id === project).consumed_Hrs) - (effortsData.filter((eff, ind) => eff.project_Guid === project)).reduce((acc, curr) => acc + curr.efforts, 0)) < 40 ? 'tomato' : 'inherit' }}>{(allocatedProjects.find(alloc => alloc.id === project).consumed_Hrs) - (effortsData.filter((eff, ind) => eff.project_Guid === project)).reduce((acc, curr) => acc + curr.efforts, 0)}</label>
                                                    ) : (<label></label>)}
                                                </td>
                                            ))}
                                        {
                                            colcount.map((count, index) =>
                                                <td>
                                                    <label>0</label>
                                                </td>
                                            )
                                        }
                                        <td><label>{console.log(projects)}</label></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            {notepopup && <div className="popup"><div className="popup-content"><span className="close" onClick={togglePopup}>&times;</span><NotePopup projects={allocatedProjects} date={worknotedate} notesData={notesData} OnchangeNote={HanldeNoteChanges} employee_Id={employee.id} alreadyexist={projects} /></div></div>}
                            {changepasswordpopup && <div className="popup"><div className="popup-content"><span className="close" onClick={togglePopup}>&times;</span><ChangePasswordPopUp /></div></div>}
                            {approveeffortpopup && <div className="popup"><div className="popup-content"><span className="close" onClick={togglePopup}>&times;</span> <ApproveEffortPopup cmonth={cmonth} cyear={cyear} /></div></div>}
                        </form>
                    </div>
                )
                }
            </section >
        </div >
    )
}


export default Timesheet4