import React from 'react'
import { IoSaveOutline } from "react-icons/io5";
import CryptoJS from 'crypto-js'
import config from '../config.json'




const EmployeePopUp = (props) => {
    const sURL = config.apiUrl//'http://localhost:5250/' //'https://82.33.19.139:9008/' 

    console.log(props.editdata)

    const HandleSubmit = (e) => {

        const Data = new FormData(e.target);
        const Jdata = Object.fromEntries(Data.entries())
        Jdata.password = CryptoJS.SHA256(Jdata.password).toString()
        console.log(Jdata)


        const url = sURL + 'api/Employee/CreateEmployee'

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json-patch+json', 'Authorization': 'bearer ' + sessionStorage.getItem('token') },
            //credentials: 'include',
            body: JSON.stringify(Jdata)
        }

        fetch(url, options)
            .then(response => response.json())
            .then(data => console.log(data.json()))
            .catch(error => console.error(error))

        //e.preventDefault();
    }

    const HandleEdit = async (e) => {
        const Data = new FormData(e.target);
        const Jdata = Object.fromEntries(Data.entries())
        Jdata.password = CryptoJS.SHA256(Jdata.password).toString()
        console.log(Jdata)

        const url = sURL + 'api/Employee/UpdateEmployee'

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json-patch+json', 'Authorization': 'bearer ' + sessionStorage.getItem('token') },
            //credentials: 'include',
            body: JSON.stringify(Jdata)
        }

        fetch(url, options)
            .then(response => response.json())
            .then(data => console.log(data.json()))
            .catch(error => console.error(error))

        //e.preventDefault();
    }

    return (
        <div>
            {Object.keys(props.editdata).length > 0 ? (
                <div>
                    <h5>Edit Employee</h5>
                    <form onSubmit={HandleEdit}>
                        <table className='Add-notes'>
                            <tbody>
                                <tr>
                                    <td>
                                        <label>Employee Name</label>
                                    </td >
                                </tr >

                                <tr>
                                    <td>
                                        <input className='form-control' name='Employee_Name' defaultValue={props.editdata.employee_Name}></input>
                                        <input type='hidden' name='Employee_Id' value={props.editdata.id} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Employee Email</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type='email' className='form-control' name='Employee_Email' defaultValue={props.editdata.employee_Email}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Employee No</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input className='form-control' name='Employee_No' defaultValue={props.editdata.employee_No}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Password</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type='password' className='form-control' name='password'></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Admin user</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {props.editdata.isAdmin === true ? (
                                            <div className='EmpAdmin'>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="isAdmin" value={1} checked />
                                                    <label for="spring">Yes</label></div>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="isAdmin" value={0} />
                                                    <label for="winter">No</label>
                                                </div>
                                            </div>
                                        ) :
                                            (<div className='EmpAdmin'>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="isAdmin" value={1} />
                                                    <label for="spring">Yes</label></div>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="isAdmin" value={0} checked />
                                                    <label for="winter">No</label>
                                                </div>
                                            </div>
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Resigned</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {props.editdata.status === true ? (
                                            <div className='EmpAdmin'>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="status" value={1} checked />
                                                    <label for="spring">Yes</label></div>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="status" value={0} />
                                                    <label for="winter">No</label>
                                                </div>
                                            </div>
                                        ) :
                                            (<div className='EmpAdmin'>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="status" value={1} />
                                                    <label for="spring">Yes</label></div>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="status" value={0} checked />
                                                    <label for="winter">No</label>
                                                </div>
                                            </div>
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Line Manager</label>
                                    </td>
                                </tr>

                                <tr>
                                    <td><select name='Line_Manager_Id' style={{ width: '100%' }}>
                                        <option value={0}>select</option>
                                        {props.employeedata.map((emp, index) => (
                                            <option
                                                key={index}
                                                value={emp.id}
                                                selected={props.editdata.line_Manager_Id === emp.id}
                                            >
                                                {emp.employee_Name}
                                            </option>
                                        ))}
                                    </select></td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>HR Partner</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td><select name='HR_Partner_Id' style={{ width: '100%' }}>
                                        <option value={0}>select</option>
                                        {props.employeedata.map((emp, index) => (
                                            <option
                                                key={index}
                                                value={emp.id}
                                                selected={props.editdata.hR_Partner_Id === emp.id}
                                            >
                                                {emp.employee_Name}
                                            </option>
                                        ))}
                                    </select></td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={2}>
                                        <br></br>
                                        <button type="submit" class="btn btn-block mb-6" style={{ backgroundColor: 'hsl(24, 100%, 50%)', height: '35px' }}><IoSaveOutline style={{ paddingBottom: '2px' }} /> Save</button>
                                    </td>
                                </tr>
                            </tbody >
                        </table >
                    </form >
                </div>
            ) : (
                <form onSubmit={HandleSubmit}>
                    <h5>Create Employee</h5>
                    <table className='Add-notes'>
                        <tbody>
                            <tr>
                                <td>
                                    <label>Employee Name</label>
                                </td >
                            </tr >

                            <tr>
                                <td>
                                    <input className='form-control' name='Employee_Name'></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Employee Email</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input type='email' className='form-control' name='Employee_Email'></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Employee No</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input className='form-control' name='Employee_No'></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Password</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input type='password' className='form-control' name='password'></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Admin user</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='EmpAdmin'>
                                        <div className='EmpAdminbox'>
                                            <input type="radio" name="isAdmin" value={1} />
                                            <label for="spring">Yes</label></div>
                                        <div className='EmpAdminbox'>
                                            <input type="radio" name="isAdmin" value={0} checked />
                                            <label for="winter">No</label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Line Manager</td>
                            </tr>
                            <tr>
                                <td>
                                    <select name='Line_Manager_Id' style={{ width: '100%' }}>
                                        <option value={0}>select</option>
                                        {props.employeedata.map((emp, index) => (
                                            <option id={index} value={emp.id}>{emp.employee_Name}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td>HR Partner</td>
                            </tr>
                            <tr>
                                <td>
                                    <select name='HR_Partner_Id' style={{ width: '100%' }}>
                                        <option value={0}>select</option>
                                        {props.employeedata.map((emp, index) => (
                                            <option id={index} value={emp.id}>{emp.employee_Name}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: 'center' }} colSpan={2}>
                                    <br></br>
                                    <button type="submit" class="btn btn-block mb-6" style={{ backgroundColor: 'hsl(24, 100%, 50%)', height: '35px' }}><IoSaveOutline style={{ paddingBottom: '2px' }} /> Save</button>
                                </td>
                            </tr>
                        </tbody >
                    </table >
                </form >
            )}
        </div >
    )
}

export default EmployeePopUp