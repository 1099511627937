import React, { useEffect, useState, useRef } from 'react';
import logo from '../Img/logo-xl.png'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { useReactToPrint } from 'react-to-print';
import config from '../config.json'
import { useNavigate } from 'react-router-dom'
import ChangePasswordPopUp from '../Authentication/Components/ChangePasswordPopUp';
import ApproveEffortPopup from '../Timesheet/ApproveEffortPopup'
import '../custom.css';

const EffortReport = () => {

    const componentRef = useRef();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [effortsData, setEffortsData] = useState([]);
    const [notesData, setNoteData] = useState([]);
    const [projects, setProjects] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [mapping, setMapping] = useState([]);
    const [selectedProject, SetSelectedProject] = useState('');
    const [selectedEmployee, SetSelectedEmployee] = useState('');
    const [employeedata, setEmployeeData] = useState([]);
    const [dates, setDates] = useState([]);
    const [cost, setCost] = useState(0);
    const [dayrate, setDayRate] = useState(0);
    const [totalallocatedeffort, setTotalAllocatedEffort] = useState(0)
    const [consumedeffort, setConsumedEffort] = useState(0)
    const [changePasswordPopup, setChangePasswordPopup] = useState(false);
    const sURL = config.apiUrl;

    useEffect(() => {
        fetchData()
    }, [sessionStorage.getItem('token')]);

    const fetchData = async () => {
        try {
            const response = await fetch(sURL + `api/Report/GetReportDropdown`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': 'bearer ' + sessionStorage.getItem('token')
                },
                //credentials: 'include'

            });
            const data = await response.json();
            setProjects(data.projects || [])
            setMapping(data.projectemployeemapping || []);
            console.log(data)

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    }

    const HandleProjectchange = async (e) => {

        const selpro = e.target[e.target.selectedIndex].value;
        SetSelectedProject(selpro)
        const projectMapping = [...new Set(mapping.filter(item => item.project_Id === selpro).map(emp => emp.employee_Id))];
        setEmployee(projectMapping);
        SetSelectedEmployee('')

    }

    const Handleemployeechange = async (e) => {
        const selemp = e.target[e.target.selectedIndex].value;
        SetSelectedEmployee(selemp);
    }

    const GenerateReport = async (e) => {
        if (selectedProject !== '' && selectedEmployee !== '' && startDate !== '' && endDate !== '') {
            try {
                const response = await fetch(sURL + `api/Report/GetReportData/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json-patch+json', 'Authorization': 'bearer ' + sessionStorage.getItem('token')
                    },
                    //credentials: 'include',
                    body: JSON.stringify({ Employee_Id: selectedEmployee, Project_Id: selectedProject, StartDate: formatDate2(startDate), EndDate: formatDate2(endDate) })
                });
                const data = await response.json();
                setEffortsData(data.efforts);
                setNoteData(data.notes)
                setEmployeeData(data.employee)
                setCost(data.cost)
                setDates([...new Set(data.efforts.map(item => item.effort_date))])
                console.log(data)
                setDayRate(data.day_Rate)
                setConsumedEffort(data.consumed_Effort)
                setTotalAllocatedEffort(data.total_Allocated_Effort)

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        }
    }

    function formatDate2(dateString) {

        const date = new Date(dateString);
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }

        const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits (e.g., 01 instead of 1)
        const month = date.getMonth() + 1
        const year = date.getFullYear(); // Get full year
        if (month < 10) {
            return (`${day}-0${month}-${year}`);
        }
        else {
            return (`${day}-${month}-${year}`);
        }
    }

    function formatDate(dateString) {

        const date = new Date(dateString);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }

        const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits (e.g., 01 instead of 1)
        const month = date.toLocaleString('default', { month: 'short' }); // Get abbreviated month name
        const year = date.getFullYear(); // Get full year
        const weeksday = date.toLocaleString('default', { weekday: 'short' })
        return (`${day} ${weeksday}`);
    }


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handleChangePassword = () => {
        console.log(changePasswordPopup)
        setChangePasswordPopup(true);
    };

    const handleLogout = () => {
        sessionStorage.clear();
        navigate('/');
    };

    const togglePopup = (e) => {
        setChangePasswordPopup(false);
        e.preventDefault();
    };

    return (
        <div className='EffortReport'>
            <section style={{ backgroundColor: 'hsl(0, 0%, 26%)', height: '100%' }}>
                <div className="px-5 py-6 px-md-6 text-center text-lg-start" style={{ fontSize: '12px' }}>
                    <div className='container h-100'>
                        <div>
                            <br></br>
                            <img src={logo} alt='UK Powers' style={{ height: '32px', width: '180px' }} />
                        </div>
                        {/* <div className='px-4 py-5 px-md-5 text-center text-lg-start'> */}
                        <br></br>
                    </div>
                </div>
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                        <div class="spinner-grow" role="status">
                            <span class="sr-only"></span>
                        </div>
                        <div class="spinner-grow" role="status">
                            <span class="sr-only"></span>
                        </div>
                        <div class="spinner-grow" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                ) : (
                    <div style={{ backgroundColor: 'hsl(0, 0%, 96%)', color: 'hsl(217, 10%, 50.8%)' }}>
                        <div className='admin-control'>
                            <div><h6>Welcome {sessionStorage.getItem('EmpName')} &nbsp; &nbsp; Employee No: {sessionStorage.getItem('EmpNo')}</h6></div>

                            {sessionStorage.getItem('admin') === 'true' && (
                                <>
                                    <div><button className='btn btn-light' onClick={() => navigate('/timesheet')}>Home</button></div>
                                    <div><button className='btn btn-light' onClick={() => navigate('/admin')}>Settings</button></div>
                                    <div><button className='btn btn-light' onClick={() => navigate('/report')}>Report</button></div>
                                    <div><button className='btn btn-light' onClick={() => navigate('/approve')}>Approve</button></div>
                                    <br /><br />
                                </>
                            )}
                            <div><button className='btn btn-light' onClick={handleChangePassword}>Change Password</button></div>
                            <div><button className='btn btn-light' onClick={handleLogout}>Logout</button></div>
                        </div>
                        <div className='Reportcontrols'>
                            <div className='reportcontrolflex1'>
                                <div className='reportcontrolflexbox'>
                                    <label>Select Project</label>
                                    <br />
                                    <select onChange={HandleProjectchange}>
                                        <option id='0' value={0}>Select</option>
                                        {
                                            projects.map((pro, index) => (
                                                <option id={index} value={pro.id} >{pro.project_Name}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                {selectedProject !== '' ? (
                                    <div className='reportcontrolflexbox'>
                                        <label>Select Employee</label>
                                        <br />
                                        <select style={{ width: '405px' }} onChange={Handleemployeechange}>
                                            <option id='0' value={0}>Select</option>
                                            {
                                                employee.map((emp, index) => (
                                                    <option id={index} value={emp}>{mapping.find(item => item.employee_Id === emp && item.project_Id === selectedProject).employee_Name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                ) :
                                    (<div></div>)}
                            </div>
                            <div className='reportcontrolflex2'>
                                <div className='reportcontrolflexbox'>
                                    <label>Start Date &#40;MM/DD/YYYY&#41;</label>
                                    <br />
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                </div>
                                <div className='reportcontrolflexbox'>
                                    <label>End Date &#40;MM/DD/YYYY&#41;</label>
                                    <br />
                                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                                </div>
                            </div>

                            <div className='reportcontrolflex2'>
                                <button className='form-control' style={{ backgroundColor: 'tomato' }} onClick={GenerateReport}>Report</button>
                            </div>
                            <div className='reportcontrolflex2'><button className='form-control' style={{ backgroundColor: 'tomato' }} onClick={handlePrint}>Print</button></div>
                            <br></br>
                            <br></br>

                        </div>
                        {
                            selectedProject !== '' && selectedEmployee !== '' && startDate !== '' && endDate !== '' ? (

                                <div className='ReportSection' ref={componentRef} style={{ fontSize: '12px' }}>
                                    <div style={{ backgroundColor: 'hsl(0, 0%, 26%)', height: '75px', textAlign: 'left', alignContent: 'center' }}>
                                        <img src={logo} alt='UK Powers' style={{ height: '32px', width: '180px', margin: '10px' }} />
                                    </div>
                                    <div>
                                        <table className='table'>
                                            <tr className='ReportTableHead'>
                                                <td>Name: {employeedata.name}</td>
                                                <td colSpan={2}>Report Duration: {formatDate2(startDate)} to {formatDate2(endDate)} </td>
                                            </tr>
                                            <tr className='ReportTableHead'>
                                                <td>Project</td>
                                                <td>{mapping.find(item => item.employee_Id === selectedEmployee && item.project_Id === selectedProject).project_Name}</td>
                                                <td>Notes</td>
                                            </tr>
                                            <tbody className='ReportTableBody'>
                                                {
                                                    dates.map((item, index) => (
                                                        <tr>
                                                            <td style={{ width: '30%' }}>{formatDate(item)}</td>
                                                            <td style={{ width: '30%' }}>
                                                                {effortsData.find(eff => formatDate2(eff.effort_date) === formatDate2(item)).efforts}
                                                            </td>
                                                            <td style={{ width: '40%', overflow: 'hidden' }}>
                                                                {notesData.find(note => formatDate2(note.created_Date) === formatDate2(item)) ? (
                                                                    notesData.find(note => formatDate2(note.created_Date) === formatDate2(item)).description
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </td>
                                                        </tr>

                                                    ))}
                                            </tbody>
                                            <tr className='ReportFoot'>
                                                <td>
                                                    <label>Hours</label>
                                                </td>
                                                <td colSpan={2}>
                                                    <label>{(effortsData.reduce((acc, curr) => acc + curr.efforts, 0))}</label>
                                                </td>
                                            </tr>
                                            <tr className='ReportFoot'>
                                                <td>
                                                    <label>Man Days</label>
                                                </td>
                                                <td colSpan={2}>
                                                    <label>{((effortsData.reduce((acc, curr) => acc + curr.efforts, 0)) / 8).toFixed(1)}</label>
                                                </td>
                                            </tr>
                                            <tr className='ReportFoot'>
                                                <td>
                                                    <label>Cost</label>
                                                </td>
                                                <td colSpan={2}>
                                                    {(dayrate === 1) ? (<label>{((effortsData.reduce((acc, curr) => acc + curr.efforts, 0)) / 8) * cost}</label>) : (
                                                        <label>{((effortsData.reduce((acc, curr) => acc + curr.efforts, 0))) * cost}</label>
                                                    )}

                                                </td>
                                            </tr>
                                            <tr className='ReportFoot'>
                                                <td>
                                                    <label>Total Allocated Hrs</label>
                                                </td>
                                                <td colSpan={2}>
                                                    <label>{totalallocatedeffort}</label>
                                                </td>
                                            </tr>
                                            <tr className='ReportFoot'>
                                                <td>
                                                    <label>Total Cumulative Hrs</label>
                                                </td>
                                                <td colSpan={2}>
                                                    <label>{consumedeffort}</label>
                                                </td>
                                            </tr>

                                            <tr className='ReportFoot'>
                                                <td>
                                                    <label>Balance Hrs</label>
                                                </td>
                                                <td colSpan={2}>
                                                    <label>{totalallocatedeffort - consumedeffort}</label>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            ) : (<></>)}
                        {changePasswordPopup && <div className="popup"><div className="popup-content"><span className="close" onClick={togglePopup}>&times;</span><ChangePasswordPopUp /></div></div>}
                    </div>

                )
                }
            </section >
        </div >
    )
}
export default EffortReport