import React, { useEffect, useState, useRef, useTransition } from 'react';
import logo from '../Img/logo-xl.png'
import { IoArrowBackCircle } from "react-icons/io5";
import { IoArrowForwardCircle } from "react-icons/io5";
import { useReactToPrint } from 'react-to-print';
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { GiCancel } from "react-icons/gi";
import config from '../config.json'
import { useNavigate } from 'react-router-dom'
import ChangePasswordPopUp from '../Authentication/Components/ChangePasswordPopUp';
import '../custom.css';
import { CgEnter } from 'react-icons/cg';


const ApproveTimesheet = () => {
    const componentRef = useRef();
    const navigate = useNavigate();
    const email = sessionStorage.getItem('email')//'s.elangovan@ukpeltd.com'//'b4391304-e20a-47ee-ab4c-a879ab15025a'//'a8f83cd5-c8b2-4899-b1d2-f5e57611206e'
    const sURL = config.apiUrl   //'http://localhost:5250/' //'https://82.33.19.139:9008/' 
    const [data, setData] = useState([]);
    const [effortsData, setEffortsData] = useState([]);
    const [maineffortsData, setMainEffortsData] = useState([]);
    const [mainnotedata, setMainNoteData] = useState([]);
    const [notesData, setNoteData] = useState([]);
    const [allocatedProjects, setAllocatedProjects] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [cmonth, setCMonth] = useState(new Date().getMonth() + 1)
    const [cyear, setCYear] = useState(new Date().getFullYear())
    const [isLoading, setIsLoading] = useState(true);
    const [reportees, SetReportees] = useState([]);
    const [colcount, setColCount] = useState([]);
    const [selectedemployee, setSelectedEmployee] = useState('');
    const [dates, setDates] = useState([]);
    const [projects, setProjects] = useState([]);
    const [changePasswordPopup, setChangePasswordPopup] = useState(false);
    const [effortstatus, setEffortStatus] = useState([]);

    useEffect(() => {
        //fetchData()
        getReportee()
    }, [])

    const getReportee = async () => {
        try {
            const response = await fetch(sURL + `api/Activity/GetReportees/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': 'bearer ' + sessionStorage.getItem('token')
                },
                //credentials: 'include',
                body: JSON.stringify({ Email_Id: email })
            });

            const data = await response.json();
            SetReportees(data)
            console.log(data)


        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    }

    const fetchData = async (employee_Id, cmonth, cyear) => {
        try {
            const response = await fetch(sURL + `api/Activity/GetEffortsForApproval/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': 'bearer ' + sessionStorage.getItem('token')
                },
                //credentials: 'include',
                body: JSON.stringify({ Employee_Id: employee_Id, Effort_Month: cmonth, Effort_Year: cyear })
            });
            const data = await response.json();
            console.log(data)
            setEffortsData(data.activtyList)
            setNoteData(data.notes)
            setAllocatedProjects(data.allocated_Projects)
            setEffortStatus(data.effortStatus)
            setDates([...new Set((data.activtyList || []).map(item => item.effort_date))]);
            setProjects([...new Set((data.activtyList || []).map(item => item.project_Guid))]);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    }
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function formatDate(dateString) {

        const date = new Date(dateString);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }

        const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits (e.g., 01 instead of 1)
        const month = date.toLocaleString('default', { month: 'short' }); // Get abbreviated month name
        const year = date.getFullYear(); // Get full year
        const weeksday = date.toLocaleString('default', { weekday: 'short' })
        return (`${day} ${weeksday}`);
    }

    function formatDate2(dateString) {

        const date = new Date(dateString);
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }

        const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits (e.g., 01 instead of 1)
        const month = date.getMonth() + 1
        const year = date.getFullYear(); // Get full year
        if (month < 10) {
            return (`${year}-0${month}-${day}`);
        }
        else {
            return (`${year}-${month}-${day}`);
        }
    }

    function formatDate3(dateString) {

        const date = new Date(dateString);
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }

        const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits (e.g., 01 instead of 1)
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear(); // Get full year
        if (month < 10) {
            return (`${day} 0${month}`);
        }
        else {
            return (`${day} ${month}`);
        }
    }

    function formatDate4(dateString) {

        const date = new Date(dateString);
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }

        const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits (e.g., 01 instead of 1)
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear(); // Get full year

        return day;
    }

    const handleSubmit = async (e) => {
        const url = sURL + 'api/Activity/ApproveEfforts/'

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json-patch+json', 'Authorization': 'bearer ' + sessionStorage.getItem('token') },
            //credentials: 'include',
            body: JSON.stringify({ Employee_Id: selectedemployee, Effort_Month: cmonth, Effort_Year: cyear })
        };

        const response = await fetch(url, options);
        const data = await response.json()
        fetchData(selectedemployee, cmonth, cyear)
    }

    const HandleReject = async (e) => {
        const url = sURL + 'api/Effort/RejectEfforts/'
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json-patch+json', 'Authorization': 'bearer ' + sessionStorage.getItem('token') },
            //credentials: 'include',
            body: JSON.stringify({ Employee_Id: selectedemployee, Month: cmonth, Year: cyear })
        };

        const response = await fetch(url, options);
        const data = await response.json()
        fetchData(selectedemployee, cmonth, cyear)
    }

    const hanldePreviousWeek = (e) => {

        if (cmonth === 1) {

            setCMonth(12)
            setCYear(cyear - 1)
            fetchData(selectedemployee, 12, cyear - 1)

        }
        else {
            setCMonth(cmonth - 1)
            setCYear(cyear)
            fetchData(selectedemployee, cmonth - 1, cyear)
        }
        setIsLoading(true)

    }
    const hanldeNextWeek = (e) => {
        if (cmonth === 12) {
            setCMonth(1)
            setCYear(cyear + 1)
            fetchData(selectedemployee, 1, cyear + 1)
        }
        else {
            setCMonth(cmonth + 1)
            setCYear(cyear)
            fetchData(selectedemployee, cmonth + 1, cyear)
        }

        setIsLoading(true)
    }

    const weekendcolor = (date) => {
        let day = new Date(date).toLocaleString('default', { weekday: 'short' })

        if (day === 'Sun' || day === 'Sat') {
            return 'rgb(255, 218, 185)'//'rgb(211, 211, 211)' //'rgb(255, 160, 122)'
        }
        else { return 'rgb(255, 255, 255)' }
    }

    const HandleChange = (e) => {
        setSelectedEmployee(e.target[e.target.selectedIndex].value);
        console.log(e.target[e.target.selectedIndex].value)
        fetchData(e.target[e.target.selectedIndex].value, cmonth, cyear)
    }

    const handleChangePassword = () => {
        console.log(changePasswordPopup)
        setChangePasswordPopup(true);
    };

    const handleLogout = () => {
        sessionStorage.clear();
        navigate('/');
    };

    const togglePopup = (e) => {
        setChangePasswordPopup(false);
        e.preventDefault();
    };

    return (
        <div className='timesheet4'>
            <section style={{ backgroundColor: 'hsl(0, 0%, 26%)', height: '100%' }}>
                <div className="px-5 py-6 px-md-6 text-center text-lg-start" style={{ fontSize: '12px' }}>
                    <div className='container h-100'>
                        <div>
                            <br></br>
                            <img src={logo} alt='UK Powers' style={{ height: '32px', width: '180px' }} />
                        </div>
                        {/* <div className='px-4 py-5 px-md-5 text-center text-lg-start'> */}
                        <br></br>
                    </div>
                </div>
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                        <div class="spinner-grow" role="status">
                            <span class="sr-only"></span>
                        </div>
                        <div class="spinner-grow" role="status">
                            <span class="sr-only"></span>
                        </div>
                        <div class="spinner-grow" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                ) : (
                    <div style={{ backgroundColor: 'hsl(0, 0%, 96%)', color: 'hsl(217, 10%, 50.8%)' }}>
                        <div className='admin-control'>
                            <br /><br />
                            <div><h6>Welcome {sessionStorage.getItem('EmpName')} &nbsp; &nbsp; Employee No: {sessionStorage.getItem('EmpNo')}</h6></div>

                            {sessionStorage.getItem('admin') === 'true' && (
                                <>
                                    <div><button className='btn btn-light' onClick={() => navigate('/timesheet')}>Home</button></div>
                                    <div><button className='btn btn-light' onClick={() => navigate('/admin')}>Settings</button></div>
                                    <div><button className='btn btn-light' onClick={() => navigate('/report')}>Report</button></div>
                                    <div><button className='btn btn-light' onClick={() => navigate('/approve')}>Approve</button></div>
                                    <br /><br />
                                </>
                            )}
                            <div><button className='btn btn-light' onClick={handleChangePassword}>Change Password</button></div>
                            <div><button className='btn btn-light' onClick={handleLogout}>Logout</button></div>
                        </div>
                        <br></br>
                        <div>
                            <label>Employee</label>
                            <select onChange={HandleChange} Name='employee'>
                                <option value={0}>Select</option>
                                {
                                    reportees.map((emp, ind) => (
                                        (emp.id === selectedemployee && emp.id !== 0) ? (
                                            <option id={ind} value={emp.id} selected>{emp.name}</option>
                                        ) : (<option id={ind} value={emp.id}>{emp.name}</option>)

                                    ))}
                            </select>
                        </div>
                        {selectedemployee !== '0' && selectedemployee !== '' ? (<>
                            <div className='timesheetcontrols'>
                                <div className='timesheetcontrolsboxleft'><IoArrowBackCircle onClick={hanldePreviousWeek} style={{ height: '30px', width: '30px' }} /></div>
                                <div className='timesheetcontrolsboxcenter' style={{ textAlign: 'center' }}>
                                    {(dates.length > 0) ? (<label style={{ color: 'hsl(24, 100%, 50%)', fontSize: '16px' }}>{new Date(dates[0]).toLocaleString('default', { month: 'short' })}-{new Date(dates[0]).getFullYear()}</label>) : (<></>)}
                                </div>
                                <div className='timesheetcontrolsboxend'>
                                    <button type="button" class="btn btn-light" style={{ height: '35px', border: '1px solid' }} onClick={handleSubmit}><IoCheckmarkDoneSharp style={{ paddingBottom: '2px' }} onClick={handleSubmit} />Approve</button>
                                    &nbsp;<button type="button" class="btn btn-light" style={{ height: '35px', border: '1px solid' }} onClick={HandleReject}><GiCancel style={{ paddingBottom: '2px' }} onClick={HandleReject} />Reject</button>
                                    <IoArrowForwardCircle onClick={hanldeNextWeek} style={{ height: '30px', width: '30px' }} /></div>
                            </div>
                            <center>
                                <div class="card shadow-lg" style={{ backgroundcolor: 'hsl(0, 0%, 96%)', width: '50%', height: '50%' }}>
                                    <div class="card-body">
                                        <table className='table'>
                                            <tr>
                                                {effortstatus.map((item, index) =>

                                                    <td style={{ fontWeight: 'bold' }}>
                                                        <label>{formatDate4(item.start_Date)} - {formatDate3(item.end_Date)}</label>
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                {effortstatus.map((item, index) =>
                                                    <td>
                                                        <label>{item.status}</label>
                                                    </td>
                                                )}
                                            </tr>

                                        </table>
                                    </div>
                                </div>
                                <br />
                                <br />
                            </center>
                            <form ref={componentRef}>
                                {
                                    effortsData.length === 0 ? (<h2>No approval pending</h2>) : (
                                        <>
                                            <table className='table' style={{ tableLayout: 'fixed', width: '100%' }}>
                                                <thead class="tablehead">
                                                    <tr>
                                                        <td className='tdhorizontal1'>Project</td>
                                                        {
                                                            projects.map((record, index) =>
                                                                <td className='tdvertical1'>
                                                                    <div className='timesheet-dropdown'>
                                                                        <label>
                                                                            {allocatedProjects.find(item => item.id === record).project_Name}
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                            )
                                                        }

                                                        <td style={{ backgroundColor: 'lightgray' }} className='tdhorizontal1'>
                                                            <div>
                                                                <label>Daily Total</label>
                                                            </div>
                                                        </td>
                                                        <td className='timesheet4notes'>Work Description</td>
                                                    </tr>
                                                </thead>
                                                <tbody className='clienttable'>
                                                    {dates.map((date, index) => (
                                                        <tr>
                                                            <td style={{ backgroundColor: weekendcolor(date) }}> <label style={{ backgroundColor: weekendcolor(date) }}>{formatDate(date)}</label></td>

                                                            {
                                                                projects.map((project, index1) => (

                                                                    (effortsData.find(item => item.effort_date === date && item.project_Guid === project) !== undefined) ? (
                                                                        <td style={{ backgroundColor: weekendcolor(date) }}><label style={{ backgroundColor: weekendcolor(date) }}>{effortsData.find(item => item.effort_date === date && item.project_Guid === project).efforts}</label></td>
                                                                    ) : (
                                                                        <td style={{ backgroundColor: weekendcolor(date) }}><label style={{ backgroundColor: weekendcolor(date) }}>0</label></td>
                                                                    )
                                                                ))}

                                                            <td className='total-color'><label>{(effortsData.filter((eff, ind) => eff.effort_date === date).reduce((acc, curr) => acc + curr.efforts, 0))}</label></td>
                                                            {/* {

                                                            (notesData.find(item => formatDate2(item.created_Date) === formatDate2(date)) !== undefined) ?
                                                                (
                                                                    <td style={{ backgroundColor: weekendcolor(date) }}><label style={{ textAlign: 'left', backgroundColor: weekendcolor(date) }}>{(notesData.find(item => formatDate2(item.created_Date) === formatDate2(date))).description}</label></td>
                                                                )
                                                                :
                                                                (
                                                                    <td style={{ backgroundColor: weekendcolor(date) }}><label style={{ textAlign: 'left', backgroundColor: weekendcolor(date) }}></label></td>
                                                                )
                                                        } */}

                                                            {

                                                                (notesData.find(item => formatDate2(item.created_Date) === formatDate2(date)) !== undefined) ?
                                                                    (
                                                                        ((notesData.find(item => formatDate2(item.created_Date) === formatDate2(date)).status) === 0) ? (
                                                                            <td style={{ backgroundColor: weekendcolor(date), display: 'flex' }}>

                                                                                <label style={{ width: '100%', overflow: 'hidden' }}>
                                                                                    {
                                                                                        // (notesData.find(item => formatDate2(item.created_Date) === formatDate2(date))).description
                                                                                        (() => {
                                                                                            const item = notesData.filter(item => formatDate2(item.created_Date) === formatDate2(date) && item.description !== '');
                                                                                            return item.map(items => items.description).join(' | ');
                                                                                        })()
                                                                                    }
                                                                                </label>
                                                                            </td>

                                                                        ) : (
                                                                            // <td style={{ backgroundColor: weekendcolor(date) }}><label style={{ textAlign: 'left', backgroundColor: weekendcolor(date) }}> {(notesData.find(item => formatDate2(item.created_Date) === formatDate2(date))).description.map(item => item.description).join('')} </label></td>
                                                                            <td style={{ backgroundColor: weekendcolor(date) }}>
                                                                                <label style={{ textAlign: 'left', backgroundColor: weekendcolor(date), overflow: 'hidden', width: '100%', }}>
                                                                                    {(() => {
                                                                                        const item = notesData.filter(item => formatDate2(item.created_Date) === formatDate2(date) && item.description !== '');
                                                                                        return item.map(items => items.description).join(' | ');
                                                                                    })()}
                                                                                </label>
                                                                            </td>

                                                                        )
                                                                    )
                                                                    :
                                                                    (
                                                                        <td style={{ backgroundColor: weekendcolor(date), display: 'flex' }}></td>
                                                                    )
                                                            }

                                                        </tr>))}
                                                    <tr className='total-color'>
                                                        <td><label>Hours</label></td>
                                                        {
                                                            projects.map((project, index) => (

                                                                <td>
                                                                    <label>{(effortsData.filter((eff, ind) => eff.project_Guid === project)).reduce((acc, curr) => acc + curr.efforts, 0)}</label>
                                                                </td>
                                                            ))}

                                                        <td><label>{effortsData.reduce((acc, curr) => acc + curr.efforts, 0)}</label></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr className='total-color'>
                                                        <td><label>Man Days</label></td>
                                                        {
                                                            projects.map((project, index) => (
                                                                <td>
                                                                    <label>{((effortsData.filter((eff, ind) => eff.project_Guid === project)).reduce((acc, curr) => acc + curr.efforts, 0) / 8).toFixed(1)}</label>
                                                                </td>
                                                            ))}

                                                        <td><label>{((effortsData.reduce((acc, curr) => acc + curr.efforts, 0)) / 8).toFixed(1)}</label></td>
                                                        <td></td>
                                                    </tr>

                                                    <tr className='total-color'>
                                                        <td><label>Balance Hrs</label></td>
                                                        {
                                                            projects.map((project, index) => (
                                                                <td>
                                                                    <label>{(effortsData.filter((eff, ind) => eff.project_Guid === project)).reduce((acc, curr) => acc + curr.efforts, 0)}</label>
                                                                </td>
                                                            ))}

                                                        <td><label>{(effortsData.reduce((acc, curr) => acc + curr.efforts, 0))}</label></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </>
                                    )}
                            </form>
                        </>) : (<></>)}

                        {changePasswordPopup && <div className="popup"><div className="popup-content"><span className="close" onClick={togglePopup}>&times;</span><ChangePasswordPopUp /></div></div>}
                    </div>
                )
                }
            </section >
        </div >
    )
}

export default ApproveTimesheet