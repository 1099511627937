import React, { useEffect, useState } from 'react';
import logo from '../Img/logo-xl.png';
import '../custom.css';
import { IoAddCircleSharp } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import ProjectPopup from './ProjectPopup.js';
import AllocationPopup from './AllocationPopup';
import EmployeePopUp from './EmployeePopUp.js';
import CommonActivitiesPopUp from './CommonActivitiesPopUp.js';
import ChangePasswordPopUp from '../Authentication/Components/ChangePasswordPopUp.js';
import config from '../config.json';
import { BiCheckbox } from 'react-icons/bi';

const Projects = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [allproject, setAllProject] = useState([]);
    const [project, setProject] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [AllocatePopupOpen, setAllocationPopupOpen] = useState(false);
    const [employeePopupOpen, setEmployeePopupOpen] = useState(false);
    const [allocations, setAllocations] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [commonActivities, setCommonActivities] = useState([]);
    const [activitiesPopUp, setActivitiesPopUp] = useState(false);
    const [editProject, setEditProject] = useState([]);
    const [editAllocation, setEditAllocation] = useState([]);
    const [editEmployee, setEditEmployee] = useState([]);
    const [editCommonActivity, setEditCommonActivity] = useState([]);
    const [changePasswordPopup, setChangePasswordPopup] = useState(false);
    const [filtercontent, setFilterContent] = useState('')

    // Pagination states
    const [projectPage, setProjectPage] = useState(1);
    const [commonActivitiesPage, setCommonActivitiesPage] = useState(1);
    const [employeesPage, setEmployeesPage] = useState(1);
    const [allocationsPage, setAllocationsPage] = useState(1);
    const itemsPerPage = 5;

    const sURL = config.apiUrl;
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(sURL + `api/Project/GetAllAdminData`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': 'bearer ' + sessionStorage.getItem('token')
                },
            });
            const data = await response.json();
            setAllProject(data.projects || []);
            setAllocations(data.allocations || []);
            setEmployees(data.employees || []);
            const data1 = (data.projects || []).filter(pro => !pro.isInternal);
            const data2 = (data.projects || []).filter(pro => pro.isInternal);
            setProject(data1);
            setCommonActivities(data.commonActivties || []);
            console.log(data.projects);
            console.log(data.allocations);
            console.log(data.commonActivties)
            console.log(data.employees)
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    function formatDate2(dateString) {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }
        const day = String(date.getDate()).padStart(2, '0');
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return month < 10 ? `${day}-0${month}-${year}` : `${day}-${month}-${year}`;
    }

    const togglePopup = (e) => {
        setIsPopupOpen(false);
        setAllocationPopupOpen(false);
        setEmployeePopupOpen(false);
        setActivitiesPopUp(false);
        setChangePasswordPopup(false);
        e.preventDefault();
    };

    const handleProjectEdit = (e, project) => {
        setEditProject(project);
        setIsPopupOpen(true);
    };

    const handleAddProject = () => {
        setEditProject([]);
        setIsPopupOpen(true);
    };

    const handleAllocationEdit = (e, allocation) => {
        setEditAllocation(allocation);
        setAllocationPopupOpen(true);
    };

    const handleAddAllocation = () => {
        setEditAllocation([]);
        setAllocationPopupOpen(true);
    };

    const handleEditEmployee = (e, employee) => {
        setEditEmployee(employee);
        setEmployeePopupOpen(true);
    };

    const handleAddEmployee = () => {
        setEditEmployee([]);
        setEmployeePopupOpen(true);
    };

    const handleAddCommonTask = () => {
        setEditCommonActivity([]);
        setActivitiesPopUp(true);
    };

    const handleEditCommonTask = (e, activity) => {
        setEditCommonActivity(activity);
        setActivitiesPopUp(true);
    };

    const handleChangePassword = () => {
        setChangePasswordPopup(true);
    };

    const handleLogout = () => {
        sessionStorage.clear();
        navigate('/');
    };
    const Handlefilter = (e) => {
        setFilterContent(e.target.value.toLowerCase())
        console.log()
    }

    // Pagination logic
    const getPaginatedData = (data, page) => {
        if (!data) return []; // Handle undefined data
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data.slice(startIndex, endIndex);
    };

    const renderPaginationControls = (totalItems, currentPage, setPage) => {
        if (!totalItems) return null; // Handle undefined totalItems
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <button key={i} onClick={() => setPage(i)} className={currentPage === i ? 'active' : ''}>
                    {i}
                </button>
            );
        }
        return <div className="pagination-controls">{pages}</div>;
    };

    return (
        <>
            <div className='Projects'>
                <section style={{ backgroundColor: 'hsl(0, 0%, 26%)', height: '100%' }}>
                    <div className="px-5 py-6 px-md-6 text-center text-lg-start" style={{ fontSize: '12px' }}>
                        <div className='container h-100'>
                            <div>
                                <br></br>
                                <img src={logo} alt='UK Powers' style={{ height: '32px', width: '180px' }} />
                            </div>
                            <br></br>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                            <div className="spinner-grow" role="status">
                                <span className="sr-only"></span>
                            </div>
                            <div className="spinner-grow" role="status">
                                <span className="sr-only"></span>
                            </div>
                            <div className="spinner-grow" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : (
                        <div className='AdminSection' style={{ backgroundColor: 'hsl(0, 0%, 96%)', color: 'hsl(217, 10%, 50.8%)' }}>
                            <div className='admin-control'>
                                <div><h6>Welcome {sessionStorage.getItem('EmpName')} &nbsp; &nbsp; Employee No: {sessionStorage.getItem('EmpNo')}</h6></div>

                                {sessionStorage.getItem('admin') === 'true' && (
                                    <>
                                        <div><button className='btn btn-light' onClick={() => navigate('/timesheet')}>Home</button></div>
                                        <div><button className='btn btn-light' onClick={() => navigate('/admin')}>Settings</button></div>
                                        <div><button className='btn btn-light' onClick={() => navigate('/report')}>Report</button></div>
                                        <div><button className='btn btn-light' onClick={() => navigate('/approve')}>Approve</button></div>
                                        <br /><br />
                                    </>
                                )}
                                <div><button className='btn btn-light' onClick={handleChangePassword}>Change Password</button></div>
                                <div><button className='btn btn-light' onClick={handleLogout}>Logout</button></div>
                            </div>
                            <br></br>
                            <div className='project_control'>
                                <div>
                                    <button className='form-control' style={{ backgroundColor: 'tomato' }} onClick={handleAddProject}>
                                        <IoAddCircleSharp style={{ height: '22px', width: '15px' }} />&nbsp; Project
                                    </button>
                                </div>
                            </div>
                            <h5>Project Details</h5>
                            <table className='table table-striped'>
                                <thead className='Table-Header'>
                                    <tr>
                                        <td>Project Name</td>
                                        <td>Client Name</td>
                                        <td>Start Date</td>
                                        <td>End Date</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getPaginatedData(project, projectPage).map((pro, index) => (
                                        <tr key={index}>
                                            <td>{pro.project_Name}</td>
                                            <td>{pro.project_Client_Name}</td>
                                            <td>{formatDate2(pro.project_Start_Date)}</td>
                                            <td>{formatDate2(pro.project_End_Date)}</td>
                                            <td><FaEdit onClick={(e) => handleProjectEdit(e, pro)} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {renderPaginationControls(project?.length, projectPage, setProjectPage)}

                            {/* <div className='project_control'>
                                <div>
                                    <button className='form-control' style={{ backgroundColor: 'tomato' }} onClick={handleAddCommonTask}>
                                        <IoAddCircleSharp style={{ height: '22px', width: '15px' }} />&nbsp; Activities
                                    </button>
                                </div>
                            </div>
                            <h5>Common Task</h5>
                            <table className='table table-striped'>
                                <thead className='Table-Header'>
                                    <tr>
                                        <td>Activities Name</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getPaginatedData(commonActivities, commonActivitiesPage).map((pro, index) => (
                                        <tr key={index}>
                                            <td>{pro.project_Name}</td>
                                            <td><FaEdit onClick={(e) => handleEditCommonTask(e, pro)} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {renderPaginationControls(commonActivities?.length, commonActivitiesPage, setCommonActivitiesPage)} */}

                            <div className='project_control'>
                                <div>
                                    <button className='form-control' style={{ backgroundColor: 'tomato' }} onClick={handleAddEmployee}>
                                        <IoAddCircleSharp style={{ height: '22px', width: '15px' }} />&nbsp;Employee
                                    </button>
                                </div>
                            </div>
                            <h5>Employee Details</h5>
                            <table className='table table-striped'>
                                <thead className='Table-Header'>
                                    <tr>
                                        <td>Employee Name</td>
                                        <td>Employee Email</td>
                                        <td>Employee No</td>
                                        <td>Created Date</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getPaginatedData(employees, employeesPage).map((pro, index) => (
                                        <tr key={index}>
                                            <td>{pro.employee_Name}</td>
                                            <td>{pro.employee_Email}</td>
                                            <td>{pro.employee_No}</td>
                                            <td>{formatDate2(pro.created_Date)}</td>
                                            <td><FaEdit onClick={(e) => handleEditEmployee(e, pro)} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {renderPaginationControls(employees?.length, employeesPage, setEmployeesPage)}

                            <div className='project_control'>
                                <div>
                                    <button className='form-control' style={{ backgroundColor: 'tomato' }} onClick={handleAddAllocation}>
                                        <IoAddCircleSharp style={{ height: '22px', width: '15px' }} />&nbsp;Allocate
                                    </button>
                                </div>
                            </div>
                            <h5>Allocation Details</h5>

                            <table className='table table-striped'>

                                <thead className='Table-Header'>
                                    <tr>
                                        <td colSpan={11}>Filter: <input type='filter' onChange={(e) => Handlefilter(e)} style={{ width: '400px' }}></input></td>
                                    </tr>
                                    <tr>
                                        <td>Project Name</td>
                                        <td>Employee Name</td>
                                        <td>Employee Email</td>
                                        <td>Start Date</td>
                                        <td>End Date</td>
                                        <td>Allocated Hrs</td>
                                        <td>Consumed Hrs</td>
                                        <td>Cost</td>
                                        <td>Rate Model</td>
                                        <td>Status</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getPaginatedData(allocations.filter(item => String(item.project_name || '').toLowerCase().includes(filtercontent) || String(item.employee_Name || '').toLowerCase().includes(filtercontent)), allocationsPage).map((allocation, index) => (
                                        <tr key={index}>
                                            <td>{allocation.project_name}</td>
                                            <td>{allocation.employee_Name}</td>
                                            <td>{allocation.employee_Email}</td>
                                            <td>{formatDate2(allocation.allocation_Start_Date)}</td>
                                            <td>{formatDate2(allocation.allocation_End_Date)}</td>
                                            <td>{allocation.budget_Allocated}</td>
                                            <td style={{ color: allocation.consumed_Effort >= allocation.budget_Allocated ? 'tomato' : 'inherit', fontWeight: allocation.consumed_Effort >= allocation.budget_Allocated ? 'bold' : 'normal' }}>{allocation.consumed_Effort}</td>
                                            <td>{allocation.resource_cost}</td>
                                            <td>{allocation.day_Rate ? 'Day Rate' : 'Hour Rate'}</td>
                                            <td>{allocation.allocationStatus ? 'True' : 'False'}</td>
                                            <td><FaEdit onClick={(e) => handleAllocationEdit(e, allocation)} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {renderPaginationControls(allocations.filter(item => String(item.project_name || '').toLowerCase().includes(filtercontent) || String(item.employee_Name || '').toLowerCase().includes(filtercontent))?.length, allocationsPage, setAllocationsPage)}

                            {isPopupOpen && <div className="popup"><div className="popup-content"><span className="close" onClick={togglePopup}>&times;</span><ProjectPopup editdata={editProject} employeedata={employees} /></div></div>}
                            {AllocatePopupOpen && <div className="popup"><div className="popup-content"><span className="close" onClick={togglePopup}>&times;</span><AllocationPopup projectdata={project} employeedata={employees} allocationdata={allocations} editdata={editAllocation} /></div></div>}
                            {employeePopupOpen && <div className="popup"><div className="popup-content"><span className="close" onClick={togglePopup}>&times;</span><EmployeePopUp editdata={editEmployee} employeedata={employees} /></div></div>}
                            {activitiesPopUp && <div className="popup"><div className="popup-content"><span className="close" onClick={togglePopup}>&times;</span><CommonActivitiesPopUp editdata={editCommonActivity} /></div></div>}
                            {changePasswordPopup && <div className="popup"><div className="popup-content"><span className="close" onClick={togglePopup}>&times;</span><ChangePasswordPopUp /></div></div>}
                        </div>
                    )}
                </section>
            </div>
        </>
    );
};

export default Projects;
