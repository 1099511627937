import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './Authentication/Pages/Login';
import Timesheet2 from './Timesheet/Timesheet2';
import Timesheet3 from './Timesheet/Timesheet3';
import './App.css';
import AdminDashboard from './Admin/AdminDashboard';
import Timesheet4 from './Timesheet/Timesheet4';
import Project from './Admin/Projects';
import Client from './Admin/ClientBasedEffortView';
import Approve from './Admin/ApproveTimesheet';
import Report from './Admin/EffortReport';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path='/' element={<SideNavigationMenu />}></Route> */}
        <Route path="/" element={<Login />}></Route>
        <Route path="/timesheet" element={<Timesheet4 />}></Route>
        <Route path="/Admin" element={<Project />}></Route>
        <Route path='/Approve' element={<Approve />}></Route>
        <Route path='/Report' element={<Report />}></Route>
      </Routes>
    </BrowserRouter >

  );
}
export default App;
