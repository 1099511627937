import React, { useState } from 'react'
import '../custom.css'
import { IoSaveOutline } from "react-icons/io5";
import DatePicker from "react-datepicker"
import config from '../config.json'

const AllocationPopup = (props) => {

    const sURL = config.apiUrl //'http://localhost:5250/' //'https://82.33.19.139:9008/' 
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [estartDate, setEStartDate] = useState(props.editdata.allocation_Start_Date);
    const [eendDate, setEEndDate] = useState(props.editdata.allocation_End_Date);
    const [project, setProject] = useState(props.projectdata)

    //console.log(props.employeedata)
    //console.log(props.projectdata)
    //console.log(props.allocationdata)

    console.log(props.editdata)

    const HandleSubmit = (e) => {

        const Data = new FormData(e.target);
        const Jdata = Object.fromEntries(Data.entries())
        Jdata.Allocation_StartDate = formatDate2(startDate);
        Jdata.Allocation_EndDate = formatDate2(endDate);
        //console.log(Jdata)

        const url = sURL + 'api/Allocation/CreateAllocation'

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json-patch+json', 'Authorization': 'bearer ' + sessionStorage.getItem('token') },
            //credentials: 'include',
            body: JSON.stringify(Jdata)
        }

        fetch(url, options)
            .then(response => response.json())
            .then(data => console.log(data.json()))
            .catch(error => console.error(error))
        //e.preventDefault();
    }

    function formatDate2(dateString) {

        const date = new Date(dateString);
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }

        const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits (e.g., 01 instead of 1)
        const month = date.getMonth() + 1
        const year = date.getFullYear(); // Get full year
        if (month < 10) {
            return (`${year}-0${month}-${day}`);
        }
        else {
            return (`${year}-${month}-${day}`);
        }
    }
    const Handlechange = async (e) => {
        const selemp = e.target[e.target.selectedIndex].value;
        if (selemp !== 0) {
            const assigned_projects = [...new Set(props.allocationdata.filter(item => item.employee_Id === selemp).map(item => item.project_Id))]  //[...new Set(props.projectdata.filter(item1=> !item.project_Id===props.allocationdata.filter(item => item.employee_Id === selemp)).project_Id)] //  .map(item => item.project_Id)
            const unassigned = props.projectdata.filter(item => !assigned_projects.includes(item.id))
            setProject(unassigned)
            //e.preventDefault();
        }
    }

    const HandleEdit = (e) => {
        const url = sURL + 'api/Allocation/UpdateAllocation'
        const Data = new FormData(e.target);
        const Jdata = Object.fromEntries(Data.entries())
        Jdata.Allocation_Start_Date = formatDate2(estartDate)
        Jdata.Allocation_End_Date = formatDate2(eendDate)
        //adding disable item
        Jdata.Employee_Name = props.editdata.employee_Name
        Jdata.project_name = props.editdata.project_name
        Jdata.Employee_Email = props.editdata.employee_Email

        console.log(Jdata)

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json-patch+json', 'Authorization': 'bearer ' + sessionStorage.getItem('token') },
            //credentials: 'include',
            body: JSON.stringify(Jdata)
        }

        fetch(url, options)
            .then(response => response.json())
            //.then(data => setEffortsData(data.json()))
            .catch(error => console.error(error))

        // e.preventDefault();
    }
    return (
        <div>
            {
                Object.keys(props.editdata).length > 0 ? (
                    <div>
                        <h5>Edit Allocations</h5>
                        <form onSubmit={HandleEdit}>
                            <table className='Add-notes'>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <label>Employee</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <input name='Employee_Name' value={props.editdata.employee_Name} disabled style={{ width: '100%' }} />
                                            <input type='hidden' name='Employee_Id' value={props.editdata.employee_Id} />
                                            <input type='hidden' name='Allocation_Id' value={props.editdata.id} />
                                            {/* <select name='Employee_Id' style={{ width: '100%' }} onChange={Handlechange}>
                                                <option value={0}>select</option>
                                                {props.employeedata.map((emp, index) => (
                                                    <option id={index} value={emp.id} defaultValue={props.editdata.employee_Id}>{emp.employee_Name}</option>
                                                ))}
                                            </select> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Employee Email</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <input name='Employee_Email' value={props.editdata.employee_Email} disabled style={{ width: '100%' }} />
                                        </td>
                                    </tr>
                                    <tr>

                                        <td colSpan={2}>
                                            <label>Project Name</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <input type='text' name='project_name' value={props.editdata.project_name} disabled style={{ width: '100%' }}></input>
                                            <input type='hidden' name='project_Id' value={props.editdata.project_Id} disabled></input>

                                            {/* <select name='project_Id'>
                                                <option value={0}>select</option>
                                                {
                                                    project.map((pro, index) => (
                                                        <option id={index} value={pro.id} defaultValue={props.editdata.project_Id}> {pro.project_Name} </option>
                                                    ))}
                                            </select> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Start date &#40;MM/DD/YYYY&#41;</td>
                                        <td>End Date &#40;MM/DD/YYYY&#41;</td>
                                    </tr>
                                    <tr>
                                        <td> <DatePicker selected={estartDate} onChange={(date) => setEStartDate(date)} /></td>
                                        <td>
                                            <DatePicker selected={eendDate} onChange={(date) => setEEndDate(date)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            Allocated Hrs
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <input type='text' name='Budget_Allocated' style={{ width: '100%' }} defaultValue={props.editdata.budget_Allocated}></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            Resource Cost
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <input type='text' name='Resource_Cost' style={{ width: '100%' }} defaultValue={props.editdata.resource_cost}></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            Rate Model
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {(props.editdata.day_Rate === true) ? (
                                                <div className='EmpAdmin'>
                                                    <div className='EmpAdminbox'>
                                                        <input type="radio" name="Day_Rate" value={1} checked />
                                                        <label for="spring">Day Rate</label></div>
                                                    <div className='EmpAdminbox'>
                                                        <input type="radio" name="Day_Rate" value={0} />
                                                        <label for="winter">Hour Rate</label>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='EmpAdmin'>
                                                    <div className='EmpAdminbox'>
                                                        <input type="radio" name="Day_Rate" value={1} />
                                                        <label for="spring">Day Rate</label></div>
                                                    <div className='EmpAdminbox'>
                                                        <input type="radio" name="Day_Rate" value={0} checked />
                                                        <label for="winter">Hour Rate</label>
                                                    </div>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            Allocation Status
                                        </td>
                                    </tr>
                                    <td>
                                        {(props.editdata.allocationStatus === true) ? (
                                            <div className='EmpAdmin'>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="allocationStatus" value={1} checked />
                                                    <label for="spring">Hire</label></div>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="allocationStatus" value={0} />
                                                    <label for="winter">Discharge</label>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='EmpAdmin'>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="allocationStatus" value={1} />
                                                    <label for="spring">Hire</label></div>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="allocationStatus" value={0} checked />
                                                    <label for="winter">Discharge</label>
                                                </div>
                                            </div>
                                        )}
                                    </td>
                                    <tr>
                                        <td style={{ textAlign: 'center' }} colSpan={2}>
                                            <br></br>
                                            <button type="submit" class="btn btn-block mb-6" style={{ backgroundColor: 'hsl(24, 100%, 50%)', height: '35px' }}><IoSaveOutline style={{ paddingBottom: '2px' }} /> Update</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>) :
                    (< div >
                        <h5>Employee Allocation</h5>
                        <form onSubmit={HandleSubmit}>
                            <table className='Add-notes'>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <label>Employee</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            {/* <input className='form-control' name='employee_Email'></input> */}
                                            <select name='Employee_Id' style={{ width: '100%' }} onChange={Handlechange}>
                                                <option value={0}>select</option>
                                                {props.employeedata.map((emp, index) => (
                                                    <option id={index} value={emp.id}>{emp.employee_Name}</option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td colSpan={2}>
                                            <label>Project Name</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <select name='project_Id'>
                                                <option value={0}>select</option>
                                                {
                                                    project.map((pro, index) => (
                                                        <option id={index} value={pro.id}> {pro.project_Name} </option>
                                                    ))}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Start Date &#40;MM/DD/YYYY&#41;</td>
                                        <td>End Date &#40;MM/DD/YYYY&#41;</td>
                                    </tr>
                                    <tr>
                                        <td> <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /></td>
                                        <td>
                                            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            Budget Allocation in Hrs
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <input type='text' name='Budget_Allocated' style={{ width: '100%' }}></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            Resource Cost
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <input type='text' name='Resource_Cost' style={{ width: '100%' }}></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            Rate Model
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='EmpAdmin'>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="Day_Rate" value={1} />
                                                    <label for="spring">Day Rate</label></div>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="Day_Rate" value={0} checked />
                                                    <label for="winter">Hour Rate</label>
                                                </div>
                                            </div>
                                            <input type='hidden' name='allocationStatus' value={1} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'center' }} colSpan={2}>
                                            <br></br>
                                            <button type="submit" class="btn btn-block mb-6" style={{ backgroundColor: 'hsl(24, 100%, 50%)', height: '35px' }}><IoSaveOutline style={{ paddingBottom: '2px' }} /> Save</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                    )}
        </div >
    )
}

export default AllocationPopup;