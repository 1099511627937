import React, { useEffect, useState } from 'react'

const NotePopup = ({ projects, date, notesData, OnchangeNote, employee_Id, alreadyexist }) => {

    const [selectedproject, setSelectedProject] = useState('')
    const [data, setData] = useState(notesData)
    const [description, setDescription] = useState('')

    console.log(projects)
    console.log(notesData)
    console.log(data)
    console.log(alreadyexist)

    const HandleChange = async (e) => {

        const selpro = e.target[e.target.selectedIndex].value;
        setSelectedProject(selpro)

        if (data.length > 0) {
            if (data.find(item => formatDate2(item.created_Date) === formatDate2(date) && item.project_Id === selpro)) {
                setDescription(data.find(item => formatDate2(item.created_Date) === formatDate2(date) && item.project_Id === selpro).description)
            }
            else {
                setDescription('')
            }
        }
        else {
            setDescription('')
        }
    }
    function formatDate2(dateString) {

        const date = new Date(dateString);
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }

        const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits (e.g., 01 instead of 1)
        const month = date.getMonth() + 1
        const year = date.getFullYear(); // Get full year
        if (month < 10) {
            return (`${year}-0${month}-${day}`);
        }
        else {
            return (`${year}-${month}-${day}`);
        }
    }

    const HandleSubmit = (e) => {

        let updatedNote = [...data]

        const newNote = {
            employee_Id: employee_Id,
            created_Date: formatDate2(date),
            description: description,
            status: 0,
            project_Id: selectedproject,
        };

        if (updatedNote.length === 0) {
            updatedNote = [...updatedNote, newNote]
        }
        else {
            const temp = updatedNote.find(item => formatDate2(item.created_Date) === formatDate2(date) && item.project_Id === selectedproject)
            if (temp) {
                updatedNote.find(item => formatDate2(item.created_Date) === formatDate2(date) && item.project_Id === selectedproject).description = description
            }
            else {
                updatedNote = [...updatedNote, newNote]
            }
        }

        OnchangeNote(updatedNote);
        console.log(updatedNote)
    };
    const HandleWorkDescription = async (e) => {
        setDescription(e.target.value)
    }

    return (
        <div>
            <h5>Add / Edit Work Description</h5>
            <form>
                <table className='Add-notes'>
                    <tr>
                        <td style={{ textAlign: 'left' }}>Project</td>
                    </tr>
                    <tr>
                        <td>
                            <select name="Project" style={{ width: '100%' }} onChange={HandleChange}>
                                <option id={0} value={0}>Select</option>
                                {
                                    projects.map((item, index) => alreadyexist.includes(item.id) && (
                                        <option id={index} value={item.id} >{item.project_Name}</option>
                                    ))}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            Work Description
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <textarea className='form-control' name='work_description' onChange={HandleWorkDescription} defaultValue={description}></textarea>
                        </td>
                    </tr>
                    <tr>
                        <td><button type='button' className='btn btn-block mb-6' style={{ backgroundColor: 'tomato', width: '100px', fontSize: '12px' }} onClick={HandleSubmit}>Add Notes</button></td>
                    </tr>
                </table>
            </form>
        </div >
    )
}

export default NotePopup