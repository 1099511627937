import React, { useState } from 'react'
import '../custom.css'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { IoSaveOutline } from "react-icons/io5";
import config from '../config.json'

const CommonActivitiesPopUp = (props) => {

    const sURL = config.apiUrl //'http://localhost:5250/' //'https://82.33.19.139:9008/' 
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(() => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 10);
        return date;
    });

    const HandleSubmit = (e) => {
        //e.preventDefault()
        const Data = new FormData(e.target);
        const Jdata = Object.fromEntries(Data.entries())
        console.log(Jdata)

        const url = sURL + 'api/Project/CreateProject'

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json-patch+json', 'Authorization': 'bearer ' + sessionStorage.getItem('token') },
            //credentials: 'include',
            body: JSON.stringify(Jdata)
        }

        fetch(url, options)
            .then(response => response.json())
            //.then(data => setEffortsData(data.json()))
            .catch(error => console.error(error))

        //       e.preventDefault();
    }
    function formatDate2(dateString) {

        const date = new Date(dateString);
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }

        const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits (e.g., 01 instead of 1)
        const month = date.getMonth() + 1
        const year = date.getFullYear(); // Get full year
        if (month < 10) {
            return (`${day}-0${month}-${year}`);
        }
        else {
            return (`${day}-${month}-${year}`);
        }
    }

    const HandleEdit = (e) => {
        // e.preventDefault()
        const url = sURL + 'api/Project/UpdateProject'
        const Data = new FormData(e.target);
        const Jdata = Object.fromEntries(Data.entries())
        Jdata.project_Start_Date = formatDate2(startDate);
        Jdata.project_End_Date = formatDate2(endDate);

        console.log(Jdata)

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json-patch+json', 'Authorization': 'bearer ' + sessionStorage.getItem('token') },
            //credentials: 'include',
            body: JSON.stringify(Jdata)
        }

        fetch(url, options)
            .then(response => response.json())
            //.then(data => setEffortsData(data.json()))
            .catch(error => console.error(error))

        //e.preventDefault();
    }
    return (
        <div>
            {Object.keys(props.editdata).length > 0 ? (
                <div>
                    <h5>Edit Common Task</h5>
                    <form onSubmit={HandleEdit}>
                        <table className='Add-notes'>
                            <tbody>
                                <tr>
                                    <td>
                                        <label>Project Name</label>
                                    </td >
                                    <td></td>
                                </tr >
                                <tr>
                                    <td>
                                        <input className='form-control' name='Project_Name' defaultValue={props.editdata.project_Name}></input>
                                    </td>
                                    <td>
                                        <input type='hidden' className='form-control' name='Project_Budget_Days' value={0}></input>
                                    </td>
                                    <td>
                                        <input type='hidden' className='form-control' name='Project_Id' value={props.editdata.id}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type='Hidden' className='form-control' name='Project_Client_Name' value='UKPE'></input>
                                    </td>
                                    <td>
                                        <input type='Hidden' className='form-control' name='Project_Client_Email' value='nrajoo@ukpeltd.com'></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type='hidden' className='form-control' name='Project_Approver_Name' value='Neasarajan Rajoo'></input>
                                        <input type='hidden' className='form-control' name='Project_Approver_Id' value='2c8cb646-4ce3-42c0-ac44-fe8c16dd7ec9' />
                                    </td>
                                    <td>
                                        <input type='hidden' className='form-control' name='Project_Approver_Email' value='nrajoo@ukpeltd.com'></input>

                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <input type="hidden" name="isInternal" value='1' />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={2}>
                                        <br></br>
                                        <button type="submit" class="btn btn-block mb-6" style={{ backgroundColor: 'hsl(24, 100%, 50%)', height: '35px' }}><IoSaveOutline style={{ paddingBottom: '2px' }} /> Save</button>
                                    </td>
                                </tr>
                            </tbody >
                        </table >
                    </form >
                </div>
            ) : (
                <div>
                    <h5>Create Common Task</h5>
                    <form onSubmit={HandleSubmit}>
                        <table className='Add-notes'>
                            <tbody>
                                <tr>
                                    <td>
                                        <label>Project Name</label>
                                    </td >
                                    <td></td>
                                </tr >
                                <tr>
                                    <td>
                                        <input className='form-control' name='project_Name'></input>
                                    </td>
                                    <td>
                                        <input type='hidden' className='form-control' name='project_Budget_Days' value={0}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type='Hidden' className='form-control' name='project_Client_Name' value='UKPE'></input>
                                    </td>
                                    <td>
                                        <input type='Hidden' className='form-control' name='project_Client_Email' value='nrajoo@ukpeltd.com'></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type='hidden' className='form-control' name='project_Approver_Name' value='Neasarajan Rajoo'></input>
                                    </td>
                                    <td>
                                        <input type='hidden' className='form-control' name='project_Approver_Email' value='nrajoo@ukpeltd.com'></input>
                                        <input type='hidden' className='form-control' name='Project_Approver_Id' value='2c8cb646-4ce3-42c0-ac44-fe8c16dd7ec9' />
                                        <input type='hidden' name='project_Start_Date' />
                                        <input type='hidden' name='project_End_Date' />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <input type="hidden" name="isInternal" value='1' />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={2}>
                                        <br></br>
                                        <button type="submit" class="btn btn-block mb-6" style={{ backgroundColor: 'hsl(24, 100%, 50%)', height: '35px' }}><IoSaveOutline style={{ paddingBottom: '2px' }} /> Save</button>
                                    </td>
                                </tr>
                            </tbody >
                        </table >
                    </form >
                </div>)}
        </div >
    )
}

export default CommonActivitiesPopUp;