import React, { useState } from 'react'
import logo from '../../Img/logo-xl.png'
import { useNavigate } from 'react-router-dom'
import Timesheet from '../../Timesheet/Timesheet'
import CryptoJS from 'crypto-js'
import { GiConsoleController } from 'react-icons/gi'
import { jwtDecode } from 'jwt-decode'
import config from '../../config.json'

export const Login = () => {
    const sURL = config.apiUrl//'http://localhost:5250/'
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errormessage, setErrorMessage] = useState('');

    const authenticate = async (e) => {
        const encyrptedpassword = CryptoJS.SHA256(password).toString()
        console.log(encyrptedpassword)
        const url = sURL + 'api/Authentication/Authenticate/'

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json-patch+json' },
            //credentials: 'include',
            body: JSON.stringify({ Email_Id: email, Password: encyrptedpassword })
        }
        try {
            const response = await fetch(url, options)
            if (response.ok) {

                const data = await response.json()
                console.log(data)
                if (data.status) {
                    sessionStorage.setItem('email', data.email);
                    sessionStorage.setItem('admin', data.isAdmin)
                    sessionStorage.setItem('token', data.token)
                    navigate('Timesheet')
                }
                else {
                    setErrorMessage('Authentication Failed')
                }
                //e.preventDefault();
            }

        }
        catch (error) {
            console.log(error)
        }
        finally {
        }
    }

    return (

        <section style={{ backgroundColor: 'hsl(0, 0%, 26%)', height: '100vh' }}>
            <div class="px-4 py-5 px-md-5 text-center text-lg-start" /*style={{ backgroundcolor: 'hsl(0, 0%, 96%)' }}*/>
                <div class="container">
                    <div>
                        <img src={logo} alt='UK Powers' style={{ height: '32px', width: '180px' }} />
                    </div>
                    <div className='container d-flex align-items-center justify-content-center h-100'>
                        <div class="row gx-lg-5 align-items-center">
                            <div class="col-lg-6 mb-5 mb-lg-0">
                                <h1 class="my-5 display-3 fw-bold ls-tight" style={{ fontSize: '30px', color: 'hsl(24, 100%, 50%)' }}>
                                    ENGINEERED TODAY FOR TOMORROW AND BEYOND <br />
                                    <span class="text-primary"></span>
                                </h1>
                                <p style={{ color: 'hsl(217, 10%, 50.8%)' }}>
                                    A leading consultant focused in supporting clients to deliver cost effective and smart design solutions for their investments on energy networks of the future.
                                </p>
                            </div>
                            <div class="col-lg-6 mb-5 mb-lg-0">
                                <div class="card shadow-lg" style={{ backgroundcolor: 'hsl(0, 0%, 96%)' }} /*style={{ backgroundColor: 'hsl(0, 0%, 26%)' }}*/>
                                    <div class="card-body py-5 px-md-5">
                                        <form>
                                            <div className='row text-center' style={{ fontWeight: 'bold', color: 'hsl(217, 10%, 50.8%)' }}>
                                                <p>Time Sheet Management System</p>
                                                <br />
                                                <br />
                                            </div>
                                            {/* <div class="row">
                                            <div class="col-md-6 mb-4">
                                                <div class="form-outline">
                                                    <input type="text" id="form3Example1" class="form-control" />
                                                    <label class="form-label" for="form3Example1">First name</label>
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-4">
                                                <div class="form-outline">
                                                    <input type="text" id="form3Example2" class="form-control" />
                                                    <label class="form-label" for="form3Example2">Last name</label>
                                                </div>
                                            </div>
                                        </div>  */}

                                            <div class="form-outline mb-4">
                                                <input type="email" id="form3Example3" class="form-control" onChange={(e) => (setEmail(e.target.value))} />
                                                <label class="form-label" for="form3Example3">Email address</label>
                                            </div>

                                            <div class="form-outline mb-4">
                                                <input type="password" id="form3Example4" class="form-control" onChange={(e) => (setPassword(e.target.value))} />
                                                <label class="form-label" for="form3Example4">Password</label>
                                            </div>

                                            {/* <div class="form-check d-flex justify-content-center mb-4">
                                            <input class="form-check-input me-2" type="checkbox" value="" id="form2Example33" checked />
                                            <label class="form-check-label" for="form2Example33">
                                                Subscribe to our newsletter
                                            </label>
                                        </div> */}
                                            <div className='text-center'>
                                                <button type="button" class="btn btn-block mb-5" style={{ backgroundColor: 'hsl(24, 100%, 50%)' }} onClick={(e) => authenticate(e)}>
                                                    Login
                                                </button>
                                            </div>
                                            {/* <div class="text-center">
                                            <p>or sign up with:</p>
                                            <button type="button" class="btn btn-link btn-floating mx-1">
                                                <i class="fab fa-facebook-f"></i>
                                            </button>

                                            <button type="button" class="btn btn-link btn-floating mx-1">
                                                <i class="fab fa-google"></i>
                                            </button>

                                            <button type="button" class="btn btn-link btn-floating mx-1">
                                                <i class="fab fa-twitter"></i>
                                            </button>

                                            <button type="button" class="btn btn-link btn-floating mx-1">
                                                <i class="fab fa-github"></i>
                                            </button>
                                        </div> */}
                                        </form>
                                        <div className='Error' style={{ color: 'red' }}>
                                            {errormessage}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Login;