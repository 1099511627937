import React, { useState } from 'react'
import '../custom.css'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { IoSaveOutline } from "react-icons/io5";
import config from '../config.json'

const ProjectPopup = (props) => {

    const sURL = config.apiUrl//'http://localhost:5250/' //'https://82.33.19.139:9008/' 
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [estartDate, setEStartDate] = useState(props.editdata.project_Start_Date);
    const [eendDate, setEEndDate] = useState(props.editdata.project_End_Date);
    console.log(props.editdata.project_Name)
    console.log(Object.keys(props.editdata).length)
    console.log(props.editdata)

    const HandleSubmit = async (e) => {

        const Data = new FormData(e.target);
        const Jdata = Object.fromEntries(Data.entries())
        Jdata.project_Start_Date = formatDate2(startDate)
        Jdata.project_End_Date = formatDate2(endDate)
        const url = sURL + 'api/Project/CreateProject'

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json-patch+json', 'Authorization': 'bearer ' + sessionStorage.getItem('token') },
            //credentials: 'include',
            body: JSON.stringify(Jdata)
        }

        fetch(url, options)
            .then(response => response.json())
            //.then(data => setEffortsData(data.json()))
            .catch(error => console.error(error))

        // e.preventDefault();
    }
    function formatDate2(dateString) {

        const date = new Date(dateString);
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }

        const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits (e.g., 01 instead of 1)
        const month = date.getMonth() + 1
        const year = date.getFullYear(); // Get full year
        if (month < 10) {
            return (`${day}-0${month}-${year}`);
        }
        else {
            return (`${day}-${month}-${year}`);
        }
    }

    const HandleEdit = async (e) => {
        const url = sURL + 'api/Project/UpdateProject'
        const Data = new FormData(e.target);
        const Jdata = Object.fromEntries(Data.entries())
        Jdata.project_Start_Date = formatDate2(estartDate)
        Jdata.project_End_Date = formatDate2(eendDate)

        console.log(Jdata)

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json-patch+json', 'Authorization': 'bearer ' + sessionStorage.getItem('token') },
            //credentials: 'include',
            body: JSON.stringify(Jdata)
        }

        fetch(url, options)
            .then(response => response.json())
            //.then(data => setEffortsData(data.json()))
            .catch(error => console.error(error))

        //        e.preventDefault();
    }
    return (
        <div>
            {
                Object.keys(props.editdata).length > 0 ? (
                    <div>
                        <h5>Edit Project</h5>
                        <form onSubmit={HandleEdit}>
                            <table className='Adedd-notes'>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <label>Project Name</label>
                                        </td>
                                        {/* <td>
                                            <label>Budget in hrs</label>
                                        </td> */}

                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <input className='form-control' name='project_Name' defaultValue={props.editdata.project_Name}></input>
                                            <input type='hidden' className='form-control' name='project_Id' value={props.editdata.id}></input>
                                        </td>
                                        {/* <td>
                                            <input className='form-control' name='project_Budget_Days' defaultValue={props.editdata.project_Budget_Days}></input>
                                        </td> */}
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <label>Client Name</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <input className='form-control' name='project_Client_Name' defaultValue={props.editdata.project_Client_Name}></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>Project Start Date &#40;MM/DD/YYYY&#41;</label>
                                        </td>
                                        <td>
                                            <label>Project End Date &#40;MM/DD/YYYY&#41;</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <DatePicker selected={estartDate} onChange={(date) => setEStartDate(date)} />
                                        </td>
                                        <td>
                                            <DatePicker selected={eendDate} onChange={(date) => setEEndDate(date)} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={2}>
                                            <label>Project Approver</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <select name='project_Approver_Id' style={{ width: '100%' }}>
                                                <option value={0}>select</option>
                                                {props.employeedata.map((emp, index) => (
                                                    <option
                                                        key={index}
                                                        value={emp.id}
                                                        selected={props.editdata.project_Approver_Id === emp.id}
                                                    >
                                                        {emp.employee_Name}
                                                    </option>
                                                ))}
                                            </select>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Project Closed</td>
                                    </tr>
                                    <tr>
                                        {props.editdata.status === true ? (
                                            <div className='EmpAdmin'>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="status" value={1} checked />
                                                    <label for="spring">Yes</label></div>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="status" value={0} />
                                                    <label for="winter">No</label>
                                                </div>
                                            </div>
                                        ) :
                                            (<div className='EmpAdmin'>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="status" value={1} />
                                                    <label for="spring">Yes</label></div>
                                                <div className='EmpAdminbox'>
                                                    <input type="radio" name="status" value={0} checked />
                                                    <label for="winter">No</label>
                                                </div>
                                            </div>
                                            )}
                                    </tr>
                                    <input type='hidden' name="isInternal" value='0' />
                                    <tr>
                                        <td style={{ textAlign: 'center' }} colSpan={2}>
                                            <br></br>
                                            <button type='submit' class="btn btn-block mb-6" style={{ backgroundColor: 'hsl(24, 100%, 50%)', height: '35px' }}><IoSaveOutline style={{ paddingBottom: '2px' }} />Save</button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </form>
                    </div>
                ) : (
                    <div>
                        <h5>Create Project</h5>
                        <form onSubmit={HandleSubmit}>
                            <table className='Add-notes'>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <label>Project Name</label>
                                        </td>
                                        {/* <td>
                                            <label>Budget in hrs</label>
                                        </td> */}

                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <input className='form-control' name='project_Name'></input>
                                        </td>
                                        {/* <td>npm
                                            <input className='form-control' name='project_Budget_Days'></input>
                                        </td> */}
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <label>Client Name</label>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <input className='form-control' name='project_Client_Name'></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>Project Start Date &#40;MM/DD/YYYY&#41;</label>
                                        </td>
                                        <td>
                                            <label>Project End Date &#40;MM/DD/YYYY&#41;</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                        </td>
                                        <td>
                                            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={2}>
                                            <label>Project Approver</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <select name='project_Approver_Id' style={{ width: '100%' }}>
                                                <option value={0}>select</option>
                                                {props.employeedata.map((emp, index) => (
                                                    <option id={index} value={emp.id}>{emp.employee_Name}</option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'center' }} colSpan={2}>
                                            <br></br>
                                            <button type='submit' class="btn btn-block mb-6" style={{ backgroundColor: 'hsl(24, 100%, 50%)', height: '35px' }}><IoSaveOutline style={{ paddingBottom: '2px' }} />Save</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                )
            }
        </div>
    )
}

export default ProjectPopup;